import { createContext, useEffect, useReducer } from "react";

// import axios from "../utils/axios";
import { isValidToken, setSession } from "../utils/jwt";
import axios from "axios";
// import { useNavigate } from "react-router-dom";
import { wedlyEnv } from "../config";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const VENDOR_SIGNIN = "VENDOR_SIGNIN";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case "SIGN_IN":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "SIGN_OUT":
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case "SIGN_UP":
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case "VENDOR_SIGNIN":
      return {
        ...state,
      };
    default:
      return state;
  }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  // const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          // const response = await axios.get("/api/auth/my-account");

          // const { user } = response.data;
          // Need api to get user info

          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: true,
              user: {
                email: "dummy@gmail.com",
                isLoggedIn: true,
                name: "dummy",
              },
            },
          });
        } else {
          dispatch({
            type: INITIALIZE,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const signIn = async (email, password) => {
    const response = await axios.post("/api/auth/sign-in", {
      email,
      password,
    });
    const { accessToken, user } = response.data;

    setSession(accessToken);
    dispatch({
      type: SIGN_IN,
      payload: {
        user,
      },
    });
  };

  const otpSignIn = async (phone_no, otp) => {
    const response = await axios.post(`${wedlyEnv.baseUrl}/v2/login/submit_otp?phone_no=${phone_no}&otp=${otp}&user_type=admin`);

    // console.log(response.data);

    const { jwt_token: accessToken } = response.data;

    // console.log(accessToken);
    //Need User Details from end point. Setting Dummy Details for now

    setSession(accessToken);
    localStorage.setItem("superAdmin", response.data.is_super_admin);
    dispatch({
      type: SIGN_IN,
      payload: {
        user: {
          email: "dummy@gmail.com",
          isLoggedIn: true,
        },
      },
    });
  };

  const vendorOtpSignIn = async (phone_no, otp) => {
    const response = await axios.post(`${wedlyEnv.baseUrl}/v2/login/submit_otp`, {
      phone_no,
      otp,
      user_type: "vendor",
    });

    // console.log(response.data);

    const { jwt_token: vendorToken, t_c } = response.data;

    // console.log(response.data);
    // console.log("123455");

    // console.log(accessToken);
    //Need User Details from end point. Setting Dummy Details for now

    // setSession(accessToken);

    window.localStorage.setItem("vendorToken", vendorToken);
    window.localStorage.setItem("t_c", t_c);

    return response.data;
  };

  const signOut = async () => {
    setSession(null);
    dispatch({ type: SIGN_OUT });
  };

  const signUp = async (email, password, firstName, lastName) => {
    const response = await axios.post("/api/auth/sign-up", {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem("accessToken", accessToken);
    dispatch({
      type: SIGN_UP,
      payload: {
        user,
      },
    });
  };

  const resetPassword = (email) => console.log(email);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "jwt",
        otpSignIn,
        signIn,
        signOut,
        signUp,
        resetPassword,
        vendorOtpSignIn,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
