import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../../config";

const initialState = {
  requirements: [],
  loading: false,
  pageNum: 0,
  previousPage: null,
  nextPage: 1,
  totalPages: 0,
  perPage: 10,
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const getRequirements = createAsyncThunk(
  "lms/getRequirements",
  async (pageNum = null) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };

    let url = `${wedlyEnv.baseUrl}/admin/lms/requirements`;

    if (pageNum) {
      url += `?page=${pageNum + 1}`;
    }

    console.log(url);

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error);
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const verifyDuplicate = createAsyncThunk(
  "req/verifyDuplicate",
  async (id) => {
    let headers = {
      Authorization: window.localStorage.accessToken,
    };

    return fetch(
      `${wedlyEnv.baseUrl}/admin/lms/requirements/${id}/verify_duplicate`,
      {
        method: "GET",
        headers,
      }
    ).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error);
        console.log(error.message);
        return error;
      }
    );
  }
);

export const vachanVerify = createAsyncThunk("req/vachanVerify", async (id) => {
  let headers = {
    Authorization: window.localStorage.accessToken,
  };

  return fetch(
    `${wedlyEnv.baseUrl}/admin/lms/requirements/${id}/owner_verification`,
    {
      method: "GET",
      headers,
    }
  ).then(
    (response) => {
      console.log(response);
      return response.json();
    },
    (error) => {
      console.log(error);
      console.log(error.message);
      return error;
    }
  );
});

export const requirementsSlice = createSlice({
  name: "requirements",
  initialState,
  reducers: {
    setPage: (state, action) => {
      console.log(action);
      state.pageNum = action.payload;
    },
  },

  extraReducers: {
    [getRequirements.fulfilled]: (state, action) => {
      console.log("requirements");
      console.log(action.payload.data);
      state.requirements = action.payload.data;

      state.previousPage = action.payload.prev_page
        ? action.payload.prev_page - 1
        : null;
      state.nextPage = action.payload.next_page
        ? action.payload.next_page - 1
        : null;
      state.perPage = action.payload.count;
      state.totalPages = action.payload.total_pages;
      state.pageNum = action.payload.current_page - 1;
      state.loading = false;
    },
    [getRequirements.pending]: (state, action) => {
      state.loading = true;
    },
    [getRequirements.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { setPage } = requirementsSlice.actions;

export default requirementsSlice.reducer;
