import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

// import { Link } from "react-router-dom";
import moment from "moment";
import {
  Box,
  Card as MuiCard,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Grid
} from "@mui/material";
import {
  DateTimePicker,
} from "@mui/lab";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from '../../pagination';
import Pagination from '@mui/material/Pagination';
import {
  getAppointments,
  updateAppointmentStatus,
} from "../../../../redux/slices/appointments";
const Card = styled(MuiCard)(spacing);

function SimpleTableDemo() {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAppointments());
  }, [dispatch]);
  const { records, pageNum, totalPages, perPage } = useSelector(
    (state) => state.appointments
  );

  const [open, setOpen] = useState(false);
  
  const { search, city, status } = useSelector((state) => state.searchStore);
  const [selectedRow, setSelectedRow] = useState(null);
  const [appointmentDate, setAppointmentDate] = React.useState(null);
  const [updateStatus, setUpdateStatus] = useState("");

  const [jumpPage, setJumpPage] = useState("");

  const handleJumpPageChange = (event) => {
    setJumpPage(event.target.value);
  };


  const handleJumpToPage = () => {
    const newPageNum = parseInt(jumpPage, 10) - 1;
    if (newPageNum >= 0 && newPageNum < totalPages) {
      // setPageNum(newPageNum);
      dispatch(getAppointments({ page: newPageNum +1 , search, city, status }))
        .unwrap()
        .then((result) => {
          // photographerslist(result.data);
        })
        .catch((error) => {
          console.log(error);
          // handle error
        });
    } else {
      alert("Invalid page number");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleJumpToPage();
    }
  };


  useEffect(() => {
    setUpdateStatus(selectedRow ? selectedRow.status : "");
    setAppointmentDate(selectedRow ? selectedRow.datetime : "");
  }, [selectedRow]);
  const statusData = ["pending", "confirmed"];

  const handleChangePage = (event, newPage) => {
    dispatch(getAppointments({ page: newPage, search, city, status }))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };

  const HandleUpdateStatus = (id) => {
    dispatch(updateAppointmentStatus({ id, status: updateStatus }))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult);
        if (originalPromiseResult.success) {
          alert(originalPromiseResult.message);
          dispatch(getAppointments({ page: null, search, city, status }));
          setOpen(false);
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  };
  return (
    <Card mb={10}>
      <Dialog open={open} onClose={() => setOpen(false)}> 
        <DialogTitle>Update Status</DialogTitle>
        <DialogContent>
          <Box m={5}>
          <Stack direction="column" spacing={3}>
          <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label="DateTimePicker"
              value={appointmentDate}
              onChange={(newValue) => {
                setAppointmentDate(newValue);
              }}
            /> 
          <FormControl>
            <InputLabel id="demo-simple-select-helper-label">Status</InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="Status"
              value={updateStatus}
              onChange={(e) => {
                console.log(e.target.value);
                setUpdateStatus(e.target.value);
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {statusData.map((item, idx) => (
                <MenuItem value={item} key={idx}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Stack>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              HandleUpdateStatus(selectedRow.id);
            }}
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Store</TableCell>
              <TableCell>User</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date Time</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {records &&
              records.map((row, idx) => {
                return (
                  <TableRow key={idx}>
                    <TableCell component="th" scope="row">
                      {row.store ? row.store.name : ""}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.user ? row.user.phone : ""}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.city ? row.city.name : ""}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {row.name ? row.name : ""}
                    </TableCell>
                    <TableCell>{row?.email ? row.email : ""}</TableCell>
                    <TableCell>{row?.phone ? row.phone : ""}</TableCell>

                    <TableCell>{row?.status ? row.status : ""}</TableCell>
                    <TableCell>
                      {row?.datetime
                        ? moment(row.datetime).format("MMMM Do, YYYY h:mm a")
                        : "-"}
                    </TableCell>
                    <TableCell>
                      <Button
                        onClick={() => {
                          setSelectedRow(row);
                          setOpen(true);
                        }}
                      >
                        Update
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
        <Grid>
          <PaginationComponent
            jumpPage={jumpPage}
            handleJumpPageChange={handleJumpPageChange}
            handleKeyPress={handleKeyPress}
            handleJumpToPage={handleJumpToPage}
            totalPages={totalPages}
            pageNum={pageNum}
            handleChangePage={handleChangePage}
            perPage={perPage}
          />
        </Grid>
      {/* <TablePagination
        component="div"
        count={totalPages && perPage ? totalPages * perPage : 0}
        page={pageNum ? pageNum : 0}
        onPageChange={handleChangePage}
        rowsPerPage={perPage ? perPage : 10}
        rowsPerPageOptions={[10]}
        onRowsPerPageChange={() => {}}
      /> */}
    </Card>
  );
}

function AppointmentsTable() {
  return (
    <React.Fragment>
      <SimpleTableDemo />
    </React.Fragment>
  );
}

export default AppointmentsTable;
