import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

const initialState = {
  storeImages: [],
  logoImages: [],
  coverImages: [],
  brandImages: [],
  loading: false,
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const getStoreImages = createAsyncThunk(
  "stores/get-stores",
  async (store_id) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };

    let url = `${wedlyEnv.baseUrl}/admin/store_images?store_id=${store_id}`;

    // console.log(url);

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        // console.log(response);

        return response.json();
      },
      (error) => {
        // console.log(error);
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const storeImageSlice = createSlice({
  name: "storeImage",
  initialState,
  reducers: {},

  extraReducers: {
    [getStoreImages.fulfilled]: (state, action) => {
      // console.log(action.payload.data);
      state.storeImages = action.payload.data.store_images;
      state.logoImages = action.payload.data.logo_images;
      state.coverImages = action.payload.data.cover_images;
      state.brandImages = action.payload.data.brand_images;
      state.loading = false;
    },
    [getStoreImages.pending]: (state, action) => {
      state.loading = true;
    },
    [getStoreImages.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default storeImageSlice.reducer;
