import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../../config";

const initialState = {
  data: {
    id: 0,
    activeCity: "",
    activeCategory: "",
    type: "draft",
    name: "",
    latitude: "",
    longitude: "",
    description: "",
    termsAndConditions: "",
    categories: [],
    discount: "",
    cashback: "",
    commision: "",
    speciality: "",
    outfitTypes: "",
    storeAddress: "",
    entityName: "",
    gstNumber: "",
    billingAddress: "",
    landLineNumber: "",
    escalationNumber: "",
    storeTimings: "",
    website: "",
    facebookLink: "",
    instagramLink: "",
    not_club_in_store_offers: false,
    isFeatured: false,
    selection: "",
    storeWorkingDays: {
      Monday: false,
      Sunday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
    },
    brandLinks: [],
  },
  contacts: [],
  storeImages: [],
  logoImages: [],
  // categories: [],
  // cities: [],
  // outfits: [],
  // specialities: [],
  message: "",
  loading: false,
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

let headers = {
  Authorization: window.localStorage.vendorToken,
};

export const verifyCoupon = createAsyncThunk(
  "vendor/verify-coupon",
  async ({ coupon }) => {
    let headers = {
      Authorization: window.localStorage.vendorToken,
    };

    const formData = new FormData();
    // console.log("hi", data);
    formData.append("coupon", coupon);
    // formData.append("amount", amount);
    // formData.append("store_id", data.store_id);
    formData.append("status", "verified");

    return fetch(`${wedlyEnv.baseUrl}/vendor/store_coupons/update_coupon`, {
      method: "POST",
      headers,
      body: formData,
    }).then(
      (response) => {
        console.log("coupon");
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log("coupon--------");
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);
export const vendorStoreDetails = createAsyncThunk(
  "vendor/get-store",
  async () => {
    return fetch(`${wedlyEnv.baseUrl}/vendor/stores/preview`, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        // console.log("hello====");
        // console.log(response);
        return response.json();
      },
      (error) => {
        // console.log("=======hello====");
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const getCats = createAsyncThunk(
  "vendor/get-all-cats",
  async (pageNum = null) => {
    let url = `${wedlyEnv.baseUrl}/data/categories`;

    // if (pageNum) {
    //   url += `?page=${pageNum}`;
    // }

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        // console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const citiesList = createAsyncThunk("vendor/cities", async () => {
  // let headers = {
  //   Authorization: window.localStorage.accessToken,
  // };

  return fetch(`${wedlyEnv.baseUrl}/data/cities`, {
    method: "GET",
    headers,
  }).then(
    (response) => {
      return response.json();
    },
    (error) => {
      console.log(error.message); //=> String
      return error;
    }
  );
});

export const outfitStylesList = createAsyncThunk(
  "vendor/outfitStyles",
  async () => {
    let headers = {
      Authorization: window.localStorage.vendorToken,
    };

    return fetch(`${wedlyEnv.baseUrl}/vendor/outfit_types`, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const specialityList = createAsyncThunk(
  "vendor/specialityList",
  async () => {
    // console.log(cat_id);
    return fetch(`${wedlyEnv.baseUrl}/vendor/specialities`, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

// export const deleteStore = createAsyncThunk("stores/delete", async (id) => {
//   let headers = {
//     Authorization: window.localStorage.accessToken,
//   };
//   return fetch(`${wedlyEnv.baseUrl}/admin/stores/${id}`, {
//     method: "DELETE",
//     headers,
//   }).then((response) => {
//     if (response.ok) {
//       return response.json();
//     } else {
//       console.log(response);
//       throw new Error(response.message);
//     }
//   });
// });

// export const sendLinkToVendor = createAsyncThunk(
//   "stores/sendLink",
//   async (id) => {
//     let headers = {
//       Authorization: window.localStorage.accessToken,
//     };
//     return fetch(`${wedlyEnv.baseUrl}/admin/stores/${id}/send_link_to_vendor`, {
//       method: "POST",
//       headers,
//     }).then((response) => {
//       if (response.ok) {
//         return response.json();
//       } else {
//         console.log(response);
//         throw new Error(response.message);
//       }
//     });
//   }
// );

export const publishStore = createAsyncThunk(
  "vendor/publish-store",
  async () => {
    const formData = new FormData();

    formData.append("status", "vendor_approved");
    return fetch(`${wedlyEnv.baseUrl}/vendor/stores/status_update`, {
      body: formData,
      method: "PUT",
      headers,
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log(response);
        throw new Error(response.message);
      }
    });
  }
);

export const editStoreDetails = createAsyncThunk(
  "vendor/edit-details",
  async (data = {}) => {
    // const formData = new FormData({ ...data });

    console.log(data);

    const formData = new FormData();
    const outfitTypeIds = data.outfitTypes
      .map((outfitType) => outfitType.value)
      .join(",");
    const categoryIds = data.activeCategory.map((item) => item.value).join(",");

    console.log(data);
    console.log("Data Check");

    formData.append("name", data.name);
    formData.append("category_ids", categoryIds);
    formData.append("city_id", data.activeCity);

    if (data.brandLinks.length) {
      formData.append(
        "brand_links",
        data.brandLinks.map((x) => x.link).join(",")
      );
    }
    let resultArray = [];

    for (let item in data.storeWorkingDays) {
      // code to be executed
      if (data.storeWorkingDays[item]) {
        resultArray.push(item);
      }
    }

    formData.append("working_days", resultArray);
    if (data.speciality && data.speciality !== "") {
      const specialityArr = data.speciality.map((x) => x.value).join(",");
      // console.log(specialityArr);
      formData.append("speciality_ids", specialityArr);
    }
    // formData.append("speciality_id", data.speciality.toString());
    if (data.outFitTypes && data.outFitTypes !== "") {
      formData.append("outfit_type_ids", outfitTypeIds);
    }

    if (data.description !== "") {
      formData.append("description", data.description);
    }
    if (data.billingAddress !== "") {
      formData.append("billing_address", data.billingAddress);
    }
    if (data.storeAddress !== "") {
      formData.append("store_address", data.storeAddress);
    }
    if (data.storeTimings !== "") {
      formData.append("timings", data.storeTimings);
    }
    if (data.gstNumber !== "") {
      formData.append("gst_number", data.gstNumber);
    }
    if (data.entityName !== "") {
      formData.append("entity_name", data.entityName);
    }
    if (data.terms_and_conditions !== "") {
      formData.append("terms_and_conditions", data.termsAndConditions);
    }
    if (data.website !== "") {
      formData.append("website", data.website);
    }
    if (data.facebookLink !== "") {
      formData.append("facebook", data.facebookLink);
    }
    if (data.instagramLink !== "") {
      formData.append("instagram", data.instagramLink);
    }
    if (data.latitude !== "") {
      formData.append("latitude", data.latitude);
    }

    if (data.longitude !== "") {
      formData.append("longitude", data.longitude);
    }

    if (data.landLineNumber && data.landLineNumber !== "") {
      formData.append("landline_number", data.landLineNumber);
    }

    if (data.escalationNumber && data.escalationNumber !== "") {
      formData.append("escalation_number", data.escalationNumber);
    }

    formData.append("discount", data.discount);

    formData.append("cashback", data.cashback);

    formData.append("commission", data.commision);

    formData.append("not_club_in_store_offers", data.not_club_in_store_offers);
    formData.append("is_featured", data.isFeatured);
    formData.append("selection", data.selection);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    let headers = {
      Authorization: window.localStorage.vendorToken,
    };

    return fetch(`${wedlyEnv.baseUrl}/vendor/stores/store_update`, {
      body: formData,
      method: "PUT",
      headers,
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        // console.log(response);
        throw new Error(response.message);
      }
    });
  }
);

export const getStoreImages = createAsyncThunk(
  "vendor/get-stores-images",
  async (store_id) => {
    let url = `${wedlyEnv.baseUrl}/vendor/store_images`;

    // console.log(url);

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        // console.log(response);

        return response.json();
      },
      (error) => {
        console.log(error);
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const createStoreImage = createAsyncThunk(
  "vendor/create-image",
  async (data = {}) => {
    const formData = new FormData();
    console.log("hi", data);
    formData.append("store_image", data.store_image);
    formData.append("name", data.name);
    // formData.append("store_id", data.store_id);
    formData.append("image_type", data.image_type);

    let url = `${wedlyEnv.baseUrl}/vendor/store_images`;

    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const getContacts = createAsyncThunk(
  "vendor/get-contacts",
  async ({ storeId }) => {
    let url = `${wedlyEnv.baseUrl}/vendor/store_contacts`;

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        // console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const editContactDetials = createAsyncThunk(
  "vendor/edit-contact",
  async (data = {}) => {
    console.log(data);
    const formData = new FormData();
    formData.append("name", data.name);
    // formData.append("store_id", data.store_id);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("contact_type", data.contact_type);
    formData.append("whatsapp_number", data.whatsapp_number);
    let url = `${wedlyEnv.baseUrl}/vendor/store_contacts/${data.contact_id}`;
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    return fetch(url, {
      body: formData,
      method: "PUT",
      headers,
    }).then(
      (response) => {
        // console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const acceptTermsAndConditions = createAsyncThunk(
  "vendor/accept_t&C",
  async (data = { settlement: "0" }) => {
    console.log(data);

    let url = `${wedlyEnv.baseUrl}/vendor/stores/t_c_approved?settlement=${data.settlement}`;

    if (data.discount !== "") {
      url += `&discount=${data.discount}`;
    }

    if (data.cashback !== "") {
      url += `&cashback=${data.cashback}`;
    }

    if (data.commission !== "") {
      url += `&commission=${data.commission}`;
    }
    console.log(url);
    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        // console.log("hello====");
        console.log(response);
        return response.json();
      },
      (error) => {
        // console.log("=======hello====");
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const createStoreContact = createAsyncThunk(
  "vendor/create-contact",
  async (data = {}) => {
    const formData = new FormData();
    // console.log("hi", data);
    formData.append("email", data.email);
    formData.append("name", data.name);
    formData.append("phone", data.phone);
    formData.append("whatsapp_number", data.whatsapp_number);
    // formData.append("store_id", data.store_id);
    formData.append("contact_type", data.contact_type);

    let url = `${wedlyEnv.baseUrl}/vendor/store_contacts`;

    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const redeemCoupon = createAsyncThunk(
  "vendor/reddem-coupon",
  async ({ coupon, amount }) => {
    const formData = new FormData();
    // console.log("hi", data);
    formData.append("coupon", coupon);
    formData.append("amount", amount);
    // formData.append("store_id", data.store_id);

    let url = `${wedlyEnv.baseUrl}/vendor/store_coupons/update_amount`;

    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const uploadInvoice = createAsyncThunk(
  "stores/upload-invoice",
  async (data = {}) => {
    const formData = new FormData();
    console.log("hi", data);
    formData.append("coupon_id", data.coupon_id);
    formData.append("file", data.file);
    formData.append("name", data.name);

    let url = `${wedlyEnv.baseUrl}/vendor/store_coupons/upload_invoice`;

    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const getVendorCoupons = createAsyncThunk(
  "vendor/get-vendor-coupons",
  async (data = null) => {
    // console.log(pageNum);

    let url = `${wedlyEnv.baseUrl}/vendor/store_coupons`;

    let pageNum, phoneNum;

    if (data) {
      pageNum = data.pageNum;
      phoneNum = data.phoneNum;
    }

    let queryArr = [];

    if (pageNum) {
      queryArr.push(`page=${pageNum}`);
    }

    if (phoneNum) {
      queryArr.push(`phone_no=${phoneNum}`);
    }

    if (queryArr.length) {
      url += `?${queryArr.join("&")}`;
    }

    if (pageNum) {
      url += `?page=${pageNum}`;
    }
    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        // console.log("hello====");
        console.log(response);
        return response.json();
      },
      (error) => {
        // console.log("=======hello====");
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const vendorInfoSlice = createSlice({
  name: "vendorInfo",
  initialState,
  reducers: {
    // setRecords: (state, action) => {
    //   state.records = action.payload.records;
    // },
    setContactRecords: (state, action) => {
      // console.log(state.records, action.payload);
      state.contacts.push(action.payload);
      // console.log(state.records);
    },
    setCity: (state, action) => {
      state.data.activeCity = action.payload;
    },
    setOutfitTypes: (state, action) => {
      state.data.outfitTypes = action.payload;
    },
    setSpeciality: (state, action) => {
      // console.log(typeof action.payload);
      state.data.speciality = action.payload;
    },
    setCategory: (state, action) => {
      state.data.activeCategory = action.payload;
    },
    setType: (state, action) => {
      state.data.type = action.payload;
    },
    setName: (state, action) => {
      state.data.name = action.payload;
    },
    setLat: (state, action) => {
      state.data.latitude = action.payload;
    },
    setLong: (state, action) => {
      state.data.longitude = action.payload;
    },
    setDescription: (state, action) => {
      state.data.description = action.payload;
    },
    setTermsAndConditions: (state, action) => {
      state.data.termsAndConditions = action.payload;
    },
    setError: (state, action) => {
      state.data.error = action.payload;
    },
    setDiscount: (state, action) => {
      state.data.discount = action.payload;
    },
    setCommision: (state, action) => {
      state.data.commision = action.payload;
    },
    setCashback: (state, action) => {
      state.data.cashback = action.payload;
    },
    setBillingAddress: (state, action) => {
      state.data.billingAddress = action.payload;
    },
    setGSTNumber: (state, action) => {
      state.data.gstNumber = action.payload;
    },
    setStoreAddress: (state, action) => {
      state.data.storeAddress = action.payload;
    },
    setEntityName: (state, action) => {
      state.data.entityName = action.payload;
    },
    setLandLineNumber: (state, action) => {
      state.data.landLineNumber = action.payload;
    },
    setEscalationNumber: (state, action) => {
      state.data.escalationNumber = action.payload;
    },
    setStoreTimings: (state, action) => {
      state.data.storeTimings = action.payload;
    },
    setWebsite: (state, action) => {
      state.data.website = action.payload;
    },
    setInstagramLink: (state, action) => {
      state.data.instagramLink = action.payload;
    },
    setFacebookLink: (state, action) => {
      state.data.facebookLink = action.payload;
    },
    setStoreWorkingDays: (state, action) => {
      state.data.storeWorkingDays = action.payload;
      console.log(state.data.storeWorkingDays);
    },
    setNotClubStoreOffers: (state, action) => {
      // console.log(action.payload);
      state.data.not_club_in_store_offers = action.payload;
    },
    setIsFeatured: (state, action) => {
      state.data.isFeatured = action.payload;
    },
    setSelection: (state, action) => {
      state.data.selection = action.payload;
    },
    setDefaults: (state, action) => {
      state.data = {
        activeCity: "",
        activeCategory: "",
        type: "Active",
        name: "",
        description: "",
        termsAndConditions: "",
        latitude: "",
        longitude: "",
        discount: "",
        cashback: "",
        speciality: "",
        outfitTypes: "",
        storeAddress: "",
        entityName: "",
        gstNumber: "",
        billingAddress: "",
        commision: "",
        landLineNumber: "",
        escalationNumber: "",
        storeTimings: "",
        website: "",
        facebookLink: "",
        instagramLink: "",
        not_club_in_store_offers: false,
        isFeatured: false,
        selection: "",
      };
    },
  },
  extraReducers: {
    [vendorStoreDetails.fulfilled]: (state, action) => {
      console.log(action.payload.data.store);
      // console.log(
      //   action.payload.data?.store.outfit_types?.map((outfitType) => {
      //     return { value: outfitType.id, label: outfitType.name };
      //   })
      // );
      // console.log("Rohi=====");
      // console.log(action.payload.data?.store.latitude);
      console.log(action.payload.data?.store.speciality);
      state.data = {
        id: action.payload.data?.store.id,
        type: action.payload.data?.store.status,
        name: action.payload.data?.store.name,
        activeCity: action.payload.data?.store.city,
        activeCategory: action.payload.data?.store.categories?.map((item) => ({
          value: item.id,
          label: item.name,
        })),
        // activeCategory:
        //   action.payload.data?.store.categories.length > 0
        //     ? action.payload.data?.store.categories[0].name
        //     : "",
        discount: action.payload.data?.store.discount,
        cashback: action.payload.data?.store.cashback,
        commision: action.payload.data?.store.commission,
        latitude: action.payload.data?.store.latitude,
        longitude: action.payload.data?.store.longitude,
        description: action.payload.data?.store.description,
        termsAndConditions: action.payload.data?.store.terms_and_conditions,
        // speciality: action.payload.data?.store.speciality?.id,
        speciality: action.payload.data?.store.speciality?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        }),
        outfitTypes: action.payload.data?.store.outfit_types?.map(
          (outfitType) => {
            return { value: outfitType.id, label: outfitType.name };
          }
        ),
        storeAddress:
          action.payload.data?.store.store_address !== "null"
            ? action.payload.data?.store.store_address
            : "",
        billingAddress:
          action.payload.data?.store.billing_address !== "null"
            ? action.payload.data?.store.billing_address
            : "",
        gstNumber:
          action.payload.data?.store.gst_number !== "null"
            ? action.payload.data?.store.gst_number
            : "",
        entityName:
          action.payload.data?.store.entity_name !== "null"
            ? action.payload.data?.store.entity_name
            : "",
        website:
          action.payload.data?.store.website !== "null"
            ? action.payload.data?.store.website
            : "",
        facebookLink:
          action.payload.data?.store.facebook !== "null"
            ? action.payload.data?.store.facebook
            : "",
        instagramLink:
          action.payload.data?.store.instagram !== "null"
            ? action.payload.data?.store.instagram
            : "",
        selection:
          action.payload.data?.store.selection !== "null"
            ? action.payload.data?.store.selection
            : "",
        storeTimings:
          action.payload.data?.store.timings !== "null"
            ? action.payload.data?.store.timings
            : "",
        landLineNumber:
          action.payload.data?.store.landline_number !== "null"
            ? action.payload.data?.store.landline_number
            : "",
        escalationNumber:
          action.payload.data?.store.escalation_number !== "null"
            ? action.payload.data?.store.escalation_number
            : "",
        storeWorkingDays: action.payload.data?.store.working_days,
        brandLinks: action.payload.data?.store.brand_links,
        not_club_in_store_offers:
          action.payload.data?.store.not_club_in_store_offers,
        isFeatured: action.payload.data?.store.is_featured,
      };
      state.message = "Store Details";
      state.loading = false;
    },
    [vendorStoreDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [vendorStoreDetails.rejected]: (state, action) => {
      state.message = "Failed to Fetch Store";
      state.loading = false;
    },
    [getStoreImages.fulfilled]: (state, action) => {
      // console.log(action.payload.data);
      state.storeImages = action.payload.data.store_images;
      state.logoImages = action.payload.data.logo_images;
      state.loading = false;
    },
    // [getStoreImages.pending]: (state, action) => {
    //   state.loading = true;
    // },
    [getStoreImages.rejected]: (state, action) => {
      state.loading = false;
    },
    [createStoreImage.fulfilled]: (state, action) => {
      state.message = "Store Image added sucsessfully";
      state.loading = false;
    },
    [createStoreImage.pending]: (state, action) => {
      state.loading = true;
    },
    [createStoreImage.rejected]: (state, action) => {
      state.message = "Failed to Add Image";
      state.loading = false;
    },
    [verifyCoupon.fulfilled]: (state, action) => {
      state.message = "Store Image added sucsessfully";
      state.loading = false;
    },
    [verifyCoupon.pending]: (state, action) => {
      state.loading = true;
    },
    [verifyCoupon.rejected]: (state, action) => {
      state.message = "Failed to Add Image";
      state.loading = false;
    },
    [getContacts.fulfilled]: (state, action) => {
      state.contacts = action.payload.contacts;
      state.message = "Store Image added sucsessfully";
      state.loading = false;
    },
    // [getContacts.pending]: (state, action) => {
    //   state.loading = true;
    // },
    [getContacts.rejected]: (state, action) => {
      state.message = "Failed to Load Contacts";
      state.loading = false;
    },
  },
});

export const {
  setCity,
  setCategory,
  setType,
  setName,
  setLat,
  setLong,
  setDescription,
  setTermsAndConditions,
  setDefaults,
  setError,
  setDiscount,
  setCashback,
  setSpeciality,
  setOutfitTypes,
  setBillingAddress,
  setEntityName,
  setGSTNumber,
  setStoreAddress,
  setCommision,
  setEscalationNumber,
  setLandLineNumber,
  setStoreTimings,
  setFacebookLink,
  setInstagramLink,
  setWebsite,
  setContactRecords,
  setStoreWorkingDays,
  setNotClubStoreOffers,
  setIsFeatured,
  setSelection,
} = vendorInfoSlice.actions;

export default vendorInfoSlice.reducer;
