import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../../config";

const initialState = {
  data: {
    name: "",
    businesscat: "",
    city: "",
    companyName: "",
    about: "",
    instagramHandle: "",
    fbHandle: "",
    website: "",
    email: "",
    gstNumber: "",
    alternateNumber: "",
  },
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const getPlannerCats = createAsyncThunk(
  "cats/get-planners",
  async () => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };

    let url = `${wedlyEnv.baseUrl}/admin/lms/planners/business_categories_list`;

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        console.log(response);
        console.log("cats");
        return response.json();
      },
      (error) => {
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const createLMSPlanner = createAsyncThunk(
  "lms/create-planner",
  async (data = {}) => {
    const formData = new FormData();
    console.log(data);

    formData.append("name", data.name);
    formData.append("company_name", data.companyName);
    formData.append("city_id", data.city_id);
    formData.append("business_category_id", data.business_category_id);
    formData.append("about", data.about);
    if (data.alternateNumber !== "") {
      formData.append("alternate_number", data.alternateNumber);
    }

    if (data.instagramHandle !== "") {
      formData.append("insta_handle", data.instagramHandle);
    }
    if (data.fbHandle !== "") {
      formData.append("fb_handle", data.fbHandle);
    }

    if (data.website !== "") {
      formData.append("website", data.website);
    }
    if (data.email !== "") {
      formData.append("email", data.email);
    }

    if (data.gstNumber !== "") {
      formData.append("gst_number", data.gstNumber);
    }
    // formData.append("id", data.id);

    let headers = {
      // "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };
    let url = `${wedlyEnv.baseUrl}/admin/lms/planners`;
    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        console.log(response);
        console.log("created");
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const plannersSlice = createSlice({
  name: "plannersCats",
  initialState,
  reducers: {
    setName: (state, action) => {
      console.log(action.payload);
      state.data.name = action.payload;
    },
    setCompanyName: (state, action) => {
      console.log(action.payload);
      state.data.companyName = action.payload;
    },
    setBusinesscat: (state, action) => {
      console.log(action.payload);
      state.data.businesscat = action.payload;
    },
    setCity: (state, action) => {
      console.log(action.payload);
      state.data.city = action.payload;
    },
    setAbout: (state, action) => {
      console.log(action.payload);
      state.data.about = action.payload;
    },
    setInstagramHandle: (state, action) => {
      console.log(action.payload);
      state.data.instagramHandle = action.payload;
    },
    setFBHandle: (state, action) => {
      console.log(action.payload);
      state.data.fbHandle = action.payload;
    },
    setWebsite: (state, action) => {
      console.log(action.payload);
      state.data.website = action.payload;
    },
    setEmail: (state, action) => {
      console.log(action.payload);
      state.data.email = action.payload;
    },
    setAlternateNumber: (state, action) => {
      console.log(action.payload);
      state.data.alternateNumber = action.payload;
    },
    setGSTNumber: (state, action) => {
      console.log(action.payload);
      state.data.gstNumber = action.payload;
    },
    setDefaults: (state, action) => {
      state.data = {
        name: "",
        businesscat: "",
        city: "",
        companyName: "",
        about: "",
        instagramHandle: "",
        fbHandle: "",
        website: "",
        email: "",
        gstNumber: "",
        alternateNumber: "",
      };
    },
  },
  extraReducers: {
    [getPlannerCats.fulfilled]: (state, action) => {
      console.log(action.payload);
      state.records = action.payload.data;
      state.loading = false;
    },
    [getPlannerCats.pending]: (state, action) => {
      state.loading = true;
    },
    [getPlannerCats.rejected]: (state, action) => {
      console.log(action.payload);
      state.loading = false;
    },
    [createLMSPlanner.fulfilled]: (state, action) => {
      state.message = "Planner added sucsessfully";
      state.loading = false;
    },
    [createLMSPlanner.pending]: (state, action) => {
      state.loading = true;
    },
    [createLMSPlanner.rejected]: (state, action) => {
      //console.log("Error");
      state.loading = false;
    },
  },
});
export const {
  setName,
  setCompanyName,
  setAbout,
  setBusinesscat,
  setCity,
  setAlternateNumber,
  setEmail,
  setInstagramHandle,
  setFBHandle,
  setWebsite,
  setGSTNumber,
  setDefaults,
} = plannersSlice.actions;

export default plannersSlice.reducer;
