import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

const initialState = {
  records: [],
  loading: false,
};

let headers = {
  "Content-Type": "application/json",
  Authorization: window.localStorage.accessToken,
};
export const getcashbacks = createAsyncThunk(
  "cashback/get-cashbacks",
  async ({ storeId }) => {
    let url = `${wedlyEnv.baseUrl}/admin/store_cashbacks?store_id=${storeId}`;

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        // console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const cashbackSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    setRecords: (state, action) => {
      // console.log(state.records, action.payload);
      state.records.push(action.payload);
      console.log(state.records);
    },
  },
  extraReducers: {
    [getcashbacks.fulfilled]: (state, action) => {
      // console.log(action);
      state.records = action.payload.data?.store_cashbacks;
      state.loading = false;
    },
    [getcashbacks.pending]: (state, action) => {
      state.loading = true;
    },
    [getcashbacks.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { setRecords } = cashbackSlice.actions;

export default cashbackSlice.reducer;
