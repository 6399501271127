import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../../config";

const initialState = {};

let headers = {
  Authorization: window.localStorage.accessToken,
};
// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const loginAsVendor = createAsyncThunk(
  "login/vendorlogin",
  async (data = {}) => {
    let url = `${wedlyEnv.baseUrl}/admin/store_contacts/login`;
    const formData = new FormData();
    console.log(data);
    formData.append("store_id", data.store_id);
    formData.append("store_contact_id", data.contact_id);
    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const vendorLoginSlice = createSlice({
  name: "loginVendor",
  initialState,
  reducers: {
    // setContactName: (state, action) => {
    //   console.log(action.payload);
    //   state.contactData.contactName = action.payload;
    // },
    // setEmail: (state, action) => {
    //   state.contactData.email = action.payload;
    // },
    // setPhone: (state, action) => {
    //   state.contactData.phone = action.payload;
    // },
    // setWhatsAppNumber: (state, action) => {
    //   state.contactData.whatsapp_number = action.payload;
    // },
    // setDefaults: (state, action) => {
    //   state.contactData = {
    //     contactName: "",
    //     phone: "",
    //     email: "",
    //     whatsapp_number: "",
    //   };
    // },
  },
  extraReducers: {
    [loginAsVendor.fulfilled]: (state, action) => {
      state.message = "Login successful";
      state.loading = false;
    },
    [loginAsVendor.pending]: (state, action) => {
      state.loading = true;
    },
    [loginAsVendor.rejected]: (state, action) => {
      state.message = "Failed to Login";
      state.loading = false;
    },
  },
});

export default vendorLoginSlice.reducer;
