import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

const initialState = {};
let headers = {
  Authorization: window.localStorage.accessToken,
};
export const editContactDetials = createAsyncThunk(
  "contacts/edit-contact",
  async (data = {}) => {
    console.log(data);
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("store_id", data.store_id);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("contact_type", data.contact_type);
    formData.append("whatsapp_number", data.whatsapp_number);
    formData.append("store_contact_id", data.contact_id);
    let url = `${wedlyEnv.baseUrl}/admin/store_contacts/update_contact`;
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    return fetch(url, {
      body: formData,
      method: "PUT",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const editContactsSlice = createSlice({
  name: "editContact",
  initialState,
  reducers: {},
  extraReducers: {
    [editContactDetials.fulfilled]: (state, action) => {
      state.message = "Contact edited sucsessfully";
      state.loading = false;
    },
    [editContactDetials.pending]: (state, action) => {
      state.loading = true;
    },
    [editContactDetials.rejected]: (state, action) => {
      state.message = "Failed to edit Contact";
      state.loading = false;
    },
  },
});

export default editContactsSlice.reducer;
