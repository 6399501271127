import React from 'react';
import { Grid, TextField, Button, Pagination } from '@mui/material';

const PaginationControls = ({
  jumpPage,
  handleJumpPageChange,
  handleKeyPress,
  handleJumpToPage,
  totalPages,
  pageNum,
  handleChangePage,
  perPage
}) => {
  return (
    <Grid container direction='row' mt={2} justifyContent='flex-end'>
      <Grid item>
        <div>
          <TextField
            label="Jump to page"
            variant="outlined"
            size="small"
            sx={{ maxWidth: '100px' }}
            value={jumpPage}
            onChange={handleJumpPageChange}
            onKeyPress={handleKeyPress} // Trigger jump on Enter key
          />
          <Button
            variant="contained"
            onClick={handleJumpToPage}
            disabled={!jumpPage || isNaN(jumpPage)}
          >
            Go
          </Button>
        </div>
      </Grid>
      <Grid item>
        <Pagination
          variant="outlined"
          shape="rounded"
          count={totalPages}
          page={pageNum + 1} // pagination expects 1-based index
          onChange={handleChangePage}
          rowsPerPage={perPage}
        />
      </Grid>
    </Grid>
  );
};

export default PaginationControls;
