import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

// const wedlyEnv = {
//   baseUrl :'https://api-test.7vachan.com'
// }

const initialState = {
  data: {
    activeCity: "",
    activeCategory: "",
    type: "Draft",
    name: "",
    latitude: "",
    longitude: "",
    description: "",
    termsAndConditions: "",
    categories: [],
    commission: "",
    cashback: "",
    selection: "",
  },
  message: "",
  loading: false,
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const addStore = createAsyncThunk(
  "stores/add-store",
  async (data = {}) => {
    // const formData = new FormData({ ...data });

    const formData = new FormData();

    console.log(data);
    console.log("Data Check");

    formData.append("name", data.name);

    const categoryIds = data.activeCategory.map((item) => item.value).join(",");
    formData.append("category_ids", categoryIds);
    formData.append("selection", data.selection);
    formData.append("city_id", data.activeCity);

    if (data.description !== "") {
      formData.append("description", data.description);
    }

    if (data.terms_and_conditions !== "") {
      formData.append("terms_and_conditions", data.termsAndConditions);
    }

    if (data.latitude !== "") {
      formData.append("latitude", data.latitude);
    }

    if (data.longitude !== "") {
      formData.append("longitude", data.longitude);
    }

    if (parseInt(data.discount)) {
      formData.append("discount", data.discount);
    }

    if (parseInt(data.cashback)) {
      formData.append("cashback", data.cashback);
    }

    const status = data.type === "Active" ? "live" : "draft";

    formData.append("status", status);
    // console.log(formData.name);
    let headers = {
      Authorization: window.localStorage.accessToken,
    };

    return fetch(`${wedlyEnv.baseUrl}/admin/stores`, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const citiesList = createAsyncThunk("stores/cities", async () => {
  let headers = {
    Authorization: window.localStorage.accessToken,
  };

  return fetch(`${wedlyEnv.baseUrl}/admin/cities`, {
    method: "GET",
    headers,
  }).then(
    (response) => {
      return response.json();
    },
    (error) => {
      console.log(error.message); //=> String
      return error;
    }
  );
});

export const outfitStylesList = createAsyncThunk(
  "stores/outfitStyles",
  async () => {
    let headers = {
      Authorization: window.localStorage.accessToken,
    };

    return fetch(`${wedlyEnv.baseUrl}/admin/outfit_types`, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const specialityList = createAsyncThunk(
  "stores/specialityList",
  async () => {
    let headers = {
      Authorization: window.localStorage.accessToken,
    };
    // console.log(cat_id);
    return fetch(`${wedlyEnv.baseUrl}/admin/specialities`, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const addStoreSlice = createSlice({
  name: "addStore",
  initialState,
  reducers: {
    // setRecords: (state, action) => {
    //   state.records = action.payload.records;
    // },
    setCity: (state, action) => {
      state.data.activeCity = action.payload;
    },
    setCategory: (state, action) => {
      state.data.activeCategory = action.payload;
    },
    setType: (state, action) => {
      state.data.type = action.payload;
    },
    setName: (state, action) => {
      state.data.name = action.payload;
    },
    setLat: (state, action) => {
      state.data.latitude = action.payload;
    },
    setLong: (state, action) => {
      state.data.longitude = action.payload;
    },
    setDescription: (state, action) => {
      state.data.description = action.payload;
    },
    setTermsAndConditions: (state, action) => {
      state.data.termsAndConditions = action.payload;
    },
    setError: (state, action) => {
      state.data.error = action.payload;
    },
    setCommission: (state, action) => {
      state.data.commission = action.payload;
    },
    setCashback: (state, action) => {
      state.data.cashback = action.payload;
    },
    setSelection: (state, action) => {
      state.data.selection = action.payload;
    },

    setDefaults: (state, action) => {
      state.data = {
        activeCity: "",
        activeCategory: "",
        type: "Active",
        name: "",
        description: "",
        termsAndConditions: "",
        latitude: "",
        longitude: "",
        commission: "",
        cashback: "",
        selection: "",
      };
    },
  },
  extraReducers: {
    [addStore.fulfilled]: (state, action) => {
      state.message = "Store Added Successfully";
      state.loading = false;
    },
    [addStore.pending]: (state, action) => {
      state.loading = true;
    },
    [addStore.rejected]: (state, action) => {
      state.message = "Failed to Add Store";
      state.loading = false;
    },
    [citiesList.fulfilled]: (state, action) => {
      // console.log(action.payload.cities);
      state.data.cities = action.payload.cities;
      state.loading = false;
    },
    [citiesList.pending]: (state, action) => {
      state.loading = true;
    },
    [citiesList.rejected]: (state, action) => {
      state.loading = false;
    },
    [outfitStylesList.fulfilled]: (state, action) => {
      // console.log(action.payload.cities);
      // state.data.cities = action.payload.data.outfit_types;
      state.loading = false;
    },
    [outfitStylesList.pending]: (state, action) => {
      state.loading = true;
    },
    [outfitStylesList.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const {
  setCity,
  setCategory,
  setType,
  setName,
  setLat,
  setLong,
  setDescription,
  setTermsAndConditions,
  setDefaults,
  setError,
  setCommission,
  setCashback,
  setSelection,
} = addStoreSlice.actions;

export default addStoreSlice.reducer;
