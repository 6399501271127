import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

const initialState = {
  data: {
    activeCity: "",
    activeCategory: "",
    type: "draft",
    name: "",
    latitude: "",
    longitude: "",
    description: "",
    termsAndConditions: "",
    categories: [],
    discount: "",
    cashback: "",
    commision: "",
    speciality: "",
    outfitTypes: "",
    storeAddress: "",
    entityName: "",
    gstNumber: "",
    billingAddress: "",
    landLineNumber: "",
    escalationNumber: "",
    storeTimings: "",
    website: "",
    facebookLink: "",
    instagramLink: "",
    slug: "",
    not_club_in_store_offers: false,
    isFeatured: false,
    selection: "",
    storeWorkingDays: {
      Monday: false,
      Sunday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
      Saturday: false,
    },
    brandLinks: [],
  },
  message: "",
  loading: false,
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const storeDetails = createAsyncThunk(
  "stores/edit-store",
  async (id) => {
    let headers = {
      Authorization: window.localStorage.accessToken,
    };

    return fetch(`${wedlyEnv.baseUrl}/admin/stores/${id}`, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        // console.log("hello====");
        // console.log(response);
        return response.json();
      },
      (error) => {
        // console.log("=======hello====");
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const updateStoreFeaturedFlag = createAsyncThunk(
  "stores/edit-store-featured",
  async (data = {}) => {
    let headers = {
      Authorization: window.localStorage.accessToken,
    };
    const formData = new FormData();
    formData.append("is_featured", !data.isFeatured);

    return fetch(`${wedlyEnv.baseUrl}/admin/stores/${data.storeId}/update_is_featured`, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const deleteStore = createAsyncThunk("stores/delete", async (id) => {
  let headers = {
    Authorization: window.localStorage.accessToken,
  };
  return fetch(`${wedlyEnv.baseUrl}/admin/stores/${id}`, {
    method: "DELETE",
    headers,
  }).then((response) => {
    if (response.ok) {
      return response.json();
    } else {
      console.log(response);
      throw new Error(response.message);
    }
  });
});

export const sendLinkToVendor = createAsyncThunk(
  "stores/sendLink",
  async (id) => {
    let headers = {
      Authorization: window.localStorage.accessToken,
    };
    return fetch(`${wedlyEnv.baseUrl}/admin/stores/${id}/send_link_to_vendor`, {
      method: "POST",
      headers,
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log(response);
        throw new Error(response.message);
      }
    });
  }
);

export const sendLinkToContact = createAsyncThunk(
  "stores/sendLinkToContact",
  async (data = {}) => {
    let headers = {
      Authorization: window.localStorage.accessToken,
    };
    let url = `${wedlyEnv.baseUrl}/admin/store_contacts/send_link`;
    const formData = new FormData();
    // console.log(data);
    formData.append("store_id", data.store_id);
    formData.append("store_contact_id", data.contact_id);
    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        // console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const publishStore = createAsyncThunk(
  "stores/publish-store",
  async (id, state, action) => {
    let headers = {
      Authorization: window.localStorage.accessToken,
    };

    const formData = new FormData();

    formData.append("publish", true);
    return fetch(`${wedlyEnv.baseUrl}/admin/stores/${id}/publish`, {
      body: formData,
      method: "POST",
      headers,
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log(response);
        throw new Error(response.message);
      }
    });
  }
);

export const editStoreDetails = createAsyncThunk(
  "store/edit-details",
  async (data = {}) => {
    // const formData = new FormData({ ...data });

    const formData = new FormData();
    const outfitTypeIds = data.outfitTypes
      .map((outfitType) => outfitType.value)
      .join(",");
    const categoryIds = data.activeCategory.map((item) => item.value).join(",");
    // console.log(outfitTypeIds);
    console.log(data);
    console.log("Data Check");

    formData.append("name", data.name);
    formData.append("category_ids", categoryIds);
    formData.append("city_id", data.activeCity);
    if (data.brandLinks.length) {
      formData.append(
        "brand_links",
        data.brandLinks.map((x) => x.link).join(",")
      );
    }
    let resultArray = [];

    for (let item in data.storeWorkingDays) {
      // code to be executed
      if (data.storeWorkingDays[item]) {
        resultArray.push(item);
      }
    }

    formData.append("working_days", resultArray);

    if (outfitTypeIds.length) {
      formData.append("outfit_type_ids", outfitTypeIds);
    }

    if (data.speciality && data.speciality !== "") {
      const specialityArr = data.speciality.map((x) => x.value).join(",");
      // console.log(specialityArr);
      formData.append("speciality_ids", specialityArr);
    }

    if (data.description && data.description !== "") {
      formData.append("description", data.description);
    }
    if (data.billingAddress && data.billingAddress !== "") {
      formData.append("billing_address", data.billingAddress);
    }
    if (data.storeAddress && data.storeAddress !== "") {
      formData.append("store_address", data.storeAddress);
    }
    if (data.gstNumber !== "") {
      formData.append("gst_number", data.gstNumber);
    }
    if (data.entityName !== "") {
      formData.append("entity_name", data.entityName);
    }
    if (data.terms_and_conditions !== "") {
      formData.append("terms_and_conditions", data.termsAndConditions);
    }

    if (data.latitude !== "") {
      formData.append("latitude", data.latitude);
    }
    if (data.website !== "") {
      formData.append("website", data.website);
    }
    if (data.facebookLink !== "") {
      formData.append("facebook", data.facebookLink);
    }
    if (data.instagramLink !== "") {
      formData.append("instagram", data.instagramLink);
    }
    if (data.slug !== "") {
      formData.append("slug", data.slug);
    }
    if (data.landLineNumber !== "") {
      formData.append("landline_number", data.landLineNumber);
    }
    if (data.escalationNumber !== "") {
      formData.append("escalation_number", data.escalationNumber);
    }
    if (data.storeTimings !== "") {
      formData.append("timings", data.storeTimings);
    }

    if (data.longitude !== "") {
      formData.append("longitude", data.longitude);
    }

    formData.append("discount", data.discount);

    formData.append("cashback", data.cashback);

    formData.append("commission", data.commision);
    formData.append("not_club_in_store_offers", data.not_club_in_store_offers);
    formData.append("is_featured", data.isFeatured);
    formData.append("selection", data.selection);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    let headers = {
      Authorization: window.localStorage.accessToken,
    };

    return fetch(`${wedlyEnv.baseUrl}/admin/stores/${data.id}`, {
      body: formData,
      method: "PUT",
      headers,
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log(response);
        throw new Error(response.message);
      }
    });
  }
);

// export const getSpecialities = createAsyncThunk(
//   "stores/get-specialities",
//   async () => {
//     let headers = {
//       Authorization: window.localStorage.accessToken,
//     };
//     return fetch(`${wedlyEnv.baseUrl}/admin/specialities`, {
//       method: "GET",
//       headers,
//     }).then((response) => {
//       if (response.ok) {
//         return response.json();
//       } else {
//         console.log(response);
//         throw new Error(response.message);
//       }
//     });
//   }
// );

export const editStoreSlice = createSlice({
  name: "editStore",
  initialState,
  reducers: {
    // setRecords: (state, action) => {
    //   state.records = action.payload.records;
    // },
    setCity: (state, action) => {
      state.data.activeCity = action.payload;
    },
    setOutfitTypes: (state, action) => {
      state.data.outfitTypes = action.payload;
    },
    setSpeciality: (state, action) => {
      // console.log(typeof action.payload);
      state.data.speciality = action.payload;
    },
    setCategory: (state, action) => {
      state.data.activeCategory = action.payload;
    },
    setType: (state, action) => {
      state.data.type = action.payload;
    },
    setName: (state, action) => {
      state.data.name = action.payload;
    },
    setLat: (state, action) => {
      state.data.latitude = action.payload;
    },
    setLong: (state, action) => {
      state.data.longitude = action.payload;
    },
    setDescription: (state, action) => {
      state.data.description = action.payload;
    },
    setTermsAndConditions: (state, action) => {
      state.data.termsAndConditions = action.payload;
    },
    setError: (state, action) => {
      state.data.error = action.payload;
    },
    setDiscount: (state, action) => {
      state.data.discount = action.payload;
    },
    setCommision: (state, action) => {
      state.data.commision = action.payload;
    },
    setCashback: (state, action) => {
      state.data.cashback = action.payload;
    },
    setBillingAddress: (state, action) => {
      state.data.billingAddress = action.payload;
    },
    setGSTNumber: (state, action) => {
      state.data.gstNumber = action.payload;
    },
    setStoreAddress: (state, action) => {
      state.data.storeAddress = action.payload;
    },
    setEntityName: (state, action) => {
      state.data.entityName = action.payload;
    },
    setLandLineNumber: (state, action) => {
      state.data.landLineNumber = action.payload;
    },
    setEscalationNumber: (state, action) => {
      state.data.escalationNumber = action.payload;
    },
    setStoreTimings: (state, action) => {
      state.data.storeTimings = action.payload;
    },
    setWebsite: (state, action) => {
      state.data.website = action.payload;
    },
    setInstagramLink: (state, action) => {
      state.data.instagramLink = action.payload;
    },
    setslug: (state, action) => {
      state.data.slug = action.payload;
    },
    setFacebookLink: (state, action) => {
      state.data.facebookLink = action.payload;
    },
    setStoreWorkingDays: (state, action) => {
      state.data.storeWorkingDays = action.payload;
      console.log(state.data.storeWorkingDays);
    },
    setNotClubStoreOffers: (state, action) => {
      // console.log(action.payload);
      state.data.not_club_in_store_offers = action.payload;
    },
    setIsFeatured: (state, action) => {
      state.data.isFeatured = action.payload;
    },
    setSelection: (state, action) => {
      state.data.selection = action.payload;
    },

    setDefaults: (state, action) => {
      state.data = {
        activeCity: "",
        activeCategory: "",
        type: "Active",
        name: "",
        description: "",
        termsAndConditions: "",
        latitude: "",
        longitude: "",
        discount: "",
        cashback: "",
        speciality: "",
        outfitTypes: "",
        storeAddress: "",
        entityName: "",
        gstNumber: "",
        billingAddress: "",
        commision: "",
        landLineNumber: "",
        escalationNumber: "",
        storeTimings: "",
        website: "",
        facebookLink: "",
        instagramLink: "",
        slug: "",
        not_club_in_store_offers: false,
        isFeatured: false,
        selection: "",
      };
    },
  },
  extraReducers: {
    [storeDetails.fulfilled]: (state, action) => {
      // console.log(action.payload.data.store);
      // console.log(action.payload.data?.store.discount);

      // console.log(action.payload.data.store.speciality);
      // console.log("Rohit");

      state.data = {
        type: action.payload.data?.store.status,
        name: action.payload.data?.store.name,
        activeCity: action.payload.data?.store.city,
        activeCategory: action.payload.data?.store.categories?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        }),
        // activeCategory:
        //   action.payload.data?.store.categories.length > 0
        //     ? action.payload.data?.store.categories[0].name
        //     : "",
        discount: action.payload.data?.store.discount,
        cashback: action.payload.data?.store.cashback,
        commision: action.payload.data?.store.commission,
        latitude: action.payload.data?.store.latitude,
        longitude: action.payload.data?.store.longitude,
        description: action.payload.data?.store.description,
        termsAndConditions: action.payload.data?.store.terms_and_conditions,
        // speciality: action.payload.data?.store.speciality?.id,
        speciality: action.payload.data?.store.speciality?.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        }),
        outfitTypes: action.payload.data?.store.outfit_types?.map(
          (outfitType) => ({ value: outfitType.id, label: outfitType.name })
        ),

        storeAddress:
          action.payload.data?.store.store_address !== "null"
            ? action.payload.data?.store.store_address
            : "",
        billingAddress:
          action.payload.data?.store.billing_address !== "null"
            ? action.payload.data?.store.billing_address
            : "",
        gstNumber:
          action.payload.data?.store.gst_number !== "null"
            ? action.payload.data?.store.gst_number
            : "",
        entityName:
          action.payload.data?.store.entity_name !== "null"
            ? action.payload.data?.store.entity_name
            : "",
        website:
          action.payload.data?.store.website !== "null"
            ? action.payload.data?.store.website
            : "",
        facebookLink:
          action.payload.data?.store.facebook !== "null"
            ? action.payload.data?.store.facebook
            : "",
        instagramLink:
          action.payload.data?.store.instagram !== "null"
            ? action.payload.data?.store.instagram
            : "",
        slug:
        action.payload.data?.store.slug !== "null"
          ? action.payload.data?.store.slug
          : "",    
        selection:
          action.payload.data?.store.selection !== "null"
            ? action.payload.data?.store.selection
            : "",
        storeTimings:
          action.payload.data?.store.timings !== "null"
            ? action.payload.data?.store.timings
            : "",
        landLineNumber:
          action.payload.data?.store.landline_number !== "null"
            ? action.payload.data?.store.landline_number
            : "",
        escalationNumber:
          action.payload.data?.store.escalation_number !== "null"
            ? action.payload.data?.store.escalation_number
            : "",
        storeWorkingDays: action.payload.data?.store.working_days,
        brandLinks: action.payload.data?.store.brand_links,
        not_club_in_store_offers:
          action.payload.data?.store.not_club_in_store_offers,
        isFeatured: action.payload.data?.store.is_featured,
      };
      state.message = "Store Details";
      state.loading = false;
    },
    [storeDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [storeDetails.rejected]: (state, action) => {
      state.message = "Failed to Fetch Store";
      state.loading = false;
    },
  },
});

export const {
  setCity,
  setCategory,
  setType,
  setName,
  setLat,
  setLong,
  setDescription,
  setTermsAndConditions,
  setDefaults,
  setError,
  setDiscount,
  setCashback,
  setSpeciality,
  setOutfitTypes,
  setBillingAddress,
  setEntityName,
  setGSTNumber,
  setStoreAddress,
  setCommision,
  setEscalationNumber,
  setLandLineNumber,
  setStoreTimings,
  setFacebookLink,
  setInstagramLink,
  setslug,
  setStoreWorkingDays,
  setWebsite,
  setNotClubStoreOffers,
  setIsFeatured,
  setSelection,
} = editStoreSlice.actions;

export default editStoreSlice.reducer;
