import React, { useState ,useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useSelector, useDispatch } from "react-redux";
import { getUsers, setSelectedPageType , gets3link } from "../../../redux/slices/users/users";
//import { wedlyEnv } from "../config";
import { spacing } from "@mui/system";
import styled from "styled-components/macro";
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import GppBadIcon from '@mui/icons-material/GppBad';
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Card as MuiCard,
  Button,
  TablePagination,
} from "@mui/material";
import PaginationComponent from '../pagination';
import { wedlyEnv } from "../../../config";

const Card = styled(MuiCard)(spacing);

function Users() {

  const dispatch = useDispatch();


  const { users, selectedPageType, pageNum, totalPages, perPage } =
    useSelector((state) => state.users);


    const csv = () => {
      dispatch(gets3link())
      .unwrap()
      .then((result) => {
        console.log(result.data)
        console.log(result.data.s3_url)
        window.open(`${result.data.s3_url}`);
      })
      .catch((error) => {
        console.log(error)
        // handle error
      })
  }


  useEffect(() => {
    console.log('useEffect users');
    dispatch(getUsers({ pageNum: 0 }));
  }, [dispatch]);


  const [jumpPage, setJumpPage] = useState("");

  const handleJumpPageChange = (event) => {
    setJumpPage(event.target.value);
  };


  const handleJumpToPage = () => {
    const newPageNum = parseInt(jumpPage, 10) - 1;
    if (newPageNum >= 0 && newPageNum < totalPages) {
      // setPageNum(newPageNum);
      dispatch(getUsers({ pageNum: newPageNum +1 }))
        .unwrap()
        .then((result) => {
          // photographerslist(result.data);
        })
        .catch((error) => {
          console.log(error);
          // handle error
        });
    } else {
      alert("Invalid page number");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleJumpToPage();
    }
  };

  const handlePageTypeChange = (e) => {
    dispatch(setSelectedPageType(e.target.value));
  };

  const handleChangePage = (event, newPage) => {
    dispatch(getUsers({ pageNum: newPage }))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult);
        // dispatch(setPage(newPage));
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here

        // //console.log("Error");
        console.log(rejectedValueOrSerializedError);
      });
  };

  return (
    <>
      <Helmet title="Users List" />

      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Users
          </Typography>
        </Grid>
        <Grid item>
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Button
              variant="contained"
              onClick={csv}
            >
              Download CSV
            </Button>

            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <InputLabel id="user-type-filter">User Type</InputLabel>
              <Select
                labelId="user-type-filter"
                id="user-type-filter"
                value={selectedPageType}
                label="User Type"
                onChange={handlePageTypeChange}
              >
                <MenuItem value="user">user</MenuItem>
                <MenuItem value="lead">lead</MenuItem>
              </Select>
            </FormControl>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={9} style={{ marginTop: "-10px" }}>
        <Grid item lg={12} xs={12}>
          <Card mb={10}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>City </TableCell>
                  <TableCell>Type</TableCell>
                  <TableCell>Signedup At</TableCell>
                  <TableCell>Is Paid</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users &&
                  users.length > 0 &&
                  users
                    .map((row) => (
                      <TableRow key={row.id}>
                        <TableCell>{row.name ?? "-"}</TableCell>
                        <TableCell>{row.email ?? "-"}</TableCell>
                        <TableCell>{row.phone_no}</TableCell>
                        <TableCell>{row.city ?? "-"} </TableCell>
                        <TableCell>{row.usertype} </TableCell>
                        <TableCell>
                          {new Date(row.signedup_at).toLocaleDateString()}
                        </TableCell>
                        <TableCell>{row.is_paid_user ? <VerifiedUserIcon /> : <GppBadIcon />}</TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            <Grid>
              <PaginationComponent
                jumpPage={jumpPage}
                handleJumpPageChange={handleJumpPageChange}
                handleKeyPress={handleKeyPress}
                handleJumpToPage={handleJumpToPage}
                totalPages={totalPages}
                pageNum={pageNum}
                handleChangePage={handleChangePage}
                perPage={perPage}
              />
            </Grid>
            {/* <TablePagination
              component="div"
              count={totalPages * perPage}
              page={pageNum}
              onPageChange={handleChangePage}
              rowsPerPage={perPage}
              rowsPerPageOptions={[10]}
              onRowsPerPageChange={() => {}}
            /> */}
          </Card>
        </Grid>
      </Grid>

      
    </>
  );
}

export default Users;
