import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

const initialState = {
  rows: [],
  page: 1,
  totalPages: 0,
  loading: false,
};

let headers = {
  Authorization: window.localStorage.accessToken,
};

export const getCouponsList = createAsyncThunk(
  "admin/coupons-list",
  async (data = null) => {
    let url = `${wedlyEnv.baseUrl}/admin/store_coupons`;

    let pageNum, phoneNum, couponStatus, couponCode, storeName, cityId, dateFrom, dateTo;

    if (data) {
      pageNum = data.pageNum;
      phoneNum = data.phone_no;
      couponStatus = data.coupon_status;
      couponCode = data.coupon_code;
      storeName = data.store_name;
      cityId = data.store_city;
      dateFrom = data.from;
      dateTo = data.to;
    }

    let queryArr = [];

    if (pageNum) {
      queryArr.push(`page=${pageNum}`);
    }

    if (phoneNum) {
      queryArr.push(`phone_no=${phoneNum}`);
    }

    if(couponStatus) {
      queryArr.push(`status=${couponStatus}`);
    }

    if(couponCode) {
      queryArr.push(`coupon_code=${couponCode}`);
    }

    if(storeName) {
      queryArr.push(`store_name=${storeName}`);
    }

    if(cityId) {
      queryArr.push(`city_id=${cityId}`);
    }

    if(dateFrom) {
      queryArr.push(`from_date=${dateFrom}`);
    }

    if(dateTo) {
      queryArr.push(`to_date=${dateTo}`);
    }

    if (queryArr.length) {
      url += `?${queryArr.join("&")}`;
    }

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const approveStoreCashback = createAsyncThunk(
  "/admin/store_coupons/update_coupon",
  async (data = {}) => {
    let url = `${wedlyEnv.baseUrl}/admin/store_coupons/update_coupon`;

    let formData = new FormData();

    formData.append("id", data.coupon_id);
    formData.append("status", data.couponStatus);
    formData.append("amount", data.amount);
    formData.append("invoice_amount", data.invoice_amount);


    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const updateCouponStatus = createAsyncThunk(
  "/admin/store_coupons/update_coupon",
  async (data = {}) => {
    let url = `${wedlyEnv.baseUrl}/admin/store_coupons/update_coupon`;

    let formData = new FormData();

    formData.append("id", data.coupon_id);
    formData.append("invoice_amount", data.invoice_amount);
    formData.append("status", data.coupon_status);
    formData.append("remarks", data.remarks);
    

    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const couponsSlice = createSlice({
  name: "coupons",
  initialState,
  extraReducers: {
    [getCouponsList.fulfilled]: (state, action) => {
      // console.log(action.payload.data);
      state.rows = action.payload.data.store_coupons;
      state.totalPages = action.payload.data.total_pages;
      state.page = action.payload.data.current_page;
      state.loading = false;
    },
    [getCouponsList.pending]: (state, action) => {
      state.loading = true;
    },
    [getCouponsList.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default couponsSlice.reducer;
