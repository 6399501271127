import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

const initialState = {};

let headers = {
  Authorization: window.localStorage.accessToken,
};
// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const createCashback = createAsyncThunk(
  "cashbacks/create-cashback",
  async (data = {}) => {
    console.log(data);
    const formData = new FormData();
    formData.append("category_id", data.category_id);
    formData.append("offer_note", data.offer_note);
    formData.append("terms_and_conditions", data.terms_and_conditions);
    formData.append("cashback_percentage", data.cashback_percentage);
    formData.append("commission_percentage", data.commission_percentage);
    formData.append("is_default", data.isDefault);
    formData.append("store_id", data.store_id);
    let url = `${wedlyEnv.baseUrl}/admin/store_cashbacks`;
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const editCashbackDetails = createAsyncThunk(
  "cashback/edit-cashback",
  async (data = {}) => {
    console.log(data);
    const formData = new FormData();
    formData.append("category_id", data.category_id);
    formData.append("offer_note", data.offer_note);
    formData.append("terms_and_conditions", data.terms_and_conditions);
    formData.append("cashback_percentage", data.cashback_percentage);
    formData.append("commission_percentage", data.commission_percentage);
    formData.append("is_default", data.isDefault);
    formData.append("store_id", data.store_id);
    formData.append("cashback_id", data.cashbackId);
    let url = `${wedlyEnv.baseUrl}/admin/store_cashbacks/cashback_update`;
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    return fetch(url, {
      body: formData,
      method: "PUT",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const addCashbackSlice = createSlice({
  name: "addcashback",
  initialState,
  reducers: {
    // setContactName: (state, action) => {
    //   console.log(action.payload);
    //   state.contactData.contactName = action.payload;
    // },
    // setEmail: (state, action) => {
    //   state.contactData.email = action.payload;
    // },
    // setPhone: (state, action) => {
    //   state.contactData.phone = action.payload;
    // },
    // setWhatsAppNumber: (state, action) => {
    //   state.contactData.whatsapp_number = action.payload;
    // },
    // setDefaults: (state, action) => {
    //   state.contactData = {
    //     contactName: "",
    //     phone: "",
    //     email: "",
    //     whatsapp_number: "",
    //   };
    // },
  },
  extraReducers: {
    [createCashback.fulfilled]: (state, action) => {
      state.message = "Cashback added sucsessfully";
      state.loading = false;
    },
    [createCashback.pending]: (state, action) => {
      state.loading = true;
    },
    [createCashback.rejected]: (state, action) => {
      state.message = "Failed to Add Cashback";
      state.loading = false;
    },
    [editCashbackDetails.fulfilled]: (state, action) => {
      state.message = "Cashback Edited sucsessfully";
      state.loading = false;
    },
    [editCashbackDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [editCashbackDetails.rejected]: (state, action) => {
      state.message = "Failed to Edit Cashback";
      state.loading = false;
    },
  },
});

export default addCashbackSlice.reducer;
