import React from "react";

import async from "./components/Async";

// All pages that rely on 3rd party components (other than Material-UI) are
// loaded asynchronously, to keep the initial JS bundle to a minimum size

// Layouts
import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
// import DocLayout from "./layouts/Doc";
// import PresentationLayout from "./layouts/Presentation";

// Guards
import AuthGuard from "./components/guards/AuthGuard";

// Auth components
import SignIn from "./pages/auth/SignIn";
// import SignUp from "./pages/auth/SignUp";
// import ResetPassword from "./pages/auth/ResetPassword";
import Page404 from "./pages/auth/Page404";
// import Page500 from "./pages/auth/Page500";

// // Components
// import Accordion from "./pages/components/Accordion";
// import Alerts from "./pages/components/Alerts";
// import Avatars from "./pages/components/Avatars";
// import Badges from "./pages/components/Badges";
// import Buttons from "./pages/components/Buttons";
// import Cards from "./pages/components/Cards";
// import Chips from "./pages/components/Chips";
// import Dialogs from "./pages/components/Dialogs";
// import Lists from "./pages/components/Lists";
// import Menus from "./pages/components/Menus";
// import Pagination from "./pages/components/Pagination";
// import Progress from "./pages/components/Progress";
// import Snackbars from "./pages/components/Snackbars";
// import Tooltips from "./pages/components/Tooltips";

// // Form components
// import SelectionCtrls from "./pages/forms/SelectionControls";
// import Selects from "./pages/forms/Selects";
// import TextFields from "./pages/forms/TextFields";

// // Icon components
// import MaterialIcons from "./pages/icons/MaterialIcons";

// // Page components
// import Blank from "./pages/pages/Blank";
// import InvoiceDetails from "./pages/pages/InvoiceDetails";
// import InvoiceList from "./pages/pages/InvoiceList";
// import Orders from "./pages/pages/Orders";
// import Pricing from "./pages/pages/Pricing";
// import Settings from "./pages/pages/Settings";
// import Projects from "./pages/pages/Projects";
// import Chat from "./pages/pages/Chat";

// Table components
// import SimpleTable from "./pages/tables/SimpleTable";
// import AdvancedTable from "./pages/tables/AdvancedTable";

// // Documentation
// import Welcome from "./pages/docs/Welcome";
// import GettingStarted from "./pages/docs/GettingStarted";
// import Routing from "./pages/docs/Routing";
// import Auth0 from "./pages/docs/auth/Auth0";
// import Cognito from "./pages/docs/auth/Cognito";
// import Firebase from "./pages/docs/auth/Firebase";
// import JWT from "./pages/docs/auth/JWT";
// import Guards from "./pages/docs/Guards";
// import EnvironmentVariables from "./pages/docs/EnvironmentVariables";
// import Deployment from "./pages/docs/Deployment";
// import Theming from "./pages/docs/Theming";
// import APICalls from "./pages/docs/APICalls";
// import Redux from "./pages/docs/Redux";
// import Internationalization from "./pages/docs/Internationalization";
// import ESLintAndPrettier from "./pages/docs/ESLintAndPrettier";
// import MigratingToNextJS from "./pages/docs/MigratingToNextJS";
// import Support from "./pages/docs/Support";
// import Changelog from "./pages/docs/Changelog";

// Landing
// import Landing from "./pages/presentation/Landing";

// Protected routes
import ProtectedPage from "./pages/protected/ProtectedPage";

import NavbarUserDropdown from "./components/navbar/NavbarUserDropdown";
import Products from "./pages/app/stores/Products/ViewProducts";

import vendorItems from "./components/sidebar/vendorItems";
import Tracking from "./pages/app/stores/Tracking/Tracking";
import Users from "./pages/app/users/Users";
import Appointments from "./pages/app/stores/store/Appointments";
import VendorList from "./pages/app/photographers/VendorList";
// import VendorLayout from "./layouts/Vendor";

// // Dashboard components
// const Default = async(() => import("./pages/dashboards/Default"));
// const Analytics = async(() => import("./pages/dashboards/Analytics"));
// const SaaS = async(() => import("./pages/dashboards/SaaS"));

// // Form components
// const Pickers = async(() => import("./pages/forms/Pickers"));
// const Editors = async(() => import("./pages/forms/Editors"));
// const Formik = async(() => import("./pages/forms/Formik"));

// // Icon components
// const FeatherIcons = async(() => import("./pages/icons/FeatherIcons"));
// const Profile = async(() => import("./pages/pages/Profile"));
// const Tasks = async(() => import("./pages/pages/Tasks"));
// const Calendar = async(() => import("./pages/pages/Calendar"));

// // Table components
// const DataGrid = async(() => import("./pages/tables/DataGrid"));

// // Chart components
// const Chartjs = async(() => import("./pages/charts/Chartjs"));

// // Maps components
// const GoogleMaps = async(() => import("./pages/maps/GoogleMaps"));
// const VectorMaps = async(() => import("./pages/maps/VectorMaps"));

const CreateProfile = async(() =>
  import("./pages/app/LMS/planners/CreateProfile")
);
// const Membership = async(() => import("./pages/app/MemberShip"));
const AddNewReq = async(() =>
  import("./pages/app/LMS/createreq/AddNewRequirements2")
);
const ClientContactDetails = async(() =>
  import("./pages/app/LMS/createreq/ClientContactDetails")
);
const AdditionalDetails = async(() =>
  import("./pages/app/LMS/createreq/AdditionalDetails")
);
const ShowAllPlanners = async(() =>
  import("./pages/app/LMS/planners/ShowAllPlanners")
);
const ViewAndEditPlanner = async(() =>
  import("./pages/app/LMS/planners/ViewAndEditPlanner")
);
const VerifyRequirement = async(() =>
  import("./pages/app/LMS/verifyreq/VerifyRequirement")
);

const Categories = async(() => import("./pages/app/categories/Categories"));

const Photographers = async(() =>
  import("./pages/app/photographers/Photographers")
);
const PhotographerDashboard = async(() =>
  import("./pages/app/photographers/Dashboard")
);
const PhotographerQuotations = async(() =>
  import("./pages/app/photographers/Quotations")
);
const PhotographerLeads = async(() =>
  import("./pages/app/photographers/Leads")
);
const PhotographerCallRequests = async(() =>
  import("./pages/app/photographers/CallList")
);
const PhotographerMessageRequests = async(() =>
  import("./pages/app/photographers/MessageList")
);
const PhotographerPaidusers = async(() =>
  import("./pages/app/photographers/Orders")
);
const EditPhotographers = async (()=> 
  import("./pages/app/photographers/EditPhotographers")
);
const PhotographerPaiduserDetails = async (()=> 
  import("./pages/app/photographers/OrderDetails")
);

const PhotographerQuoteDetails = async (()=> 
  import("./pages/app/photographers/QuoteDetails")
);

const Vendors= async (()=> 
  import("./pages/app/photographers/VendorList")
);

const Stores = async(() => import("./pages/app/stores/store/Stores"));

const AddNewCategory = async(() =>
  import("./pages/app/categories/AddNewCategory")
);
const AddProducts = async(() =>
  import("./pages/app/stores/Products/AddProduct")
);

const AddStore = async(() =>
  import("./pages/app/stores/store/AddStoreVersion2")
);

const Contacts = async(() => import("./pages/app/stores/store/Contacts"));

const EditStore = async(() => import("./pages/app/stores/store/EditStore"));

const StoreDashBoard = async(() =>
  import("./pages/app/stores/DashBoard/DashboardVersion2")
);

const AllRequirements = async(() => import("./pages/app/LMS/AllRequirements"));
const ViewRequirement = async(() =>
  import("./pages/app/LMS/createreq/ViewRequirement")
);
const AllLeads = async(() => import("./pages/app/LMS/leads/AllLeads"));

const VendorSignIn = async(() => import("./pages/app/vendor/SignIn"));

const VendorInfo = async(() => import("./pages/app/vendor/VendorInfo"));

const VendorCoupon = async(() => import("./pages/app/vendor/VerifyCoupon"));
const VendorLogout = async(() => import("./pages/app/vendor/Logout"));
const VendorTandC = async(() => import("./pages/app/vendor/VendorT&C"));

// const CreatePlanner = async(() => import("./pages/app/planners/CreatePlanner"));

const EditRequirement = async(() =>
  import("../src/pages/app/LMS/createreq/editRequirements/EditRequirement")
);

const CouponsList = async(() =>
  import("../src/pages/app/stores/store/Coupons")
);

const Groups = async(() => import("../src/pages/app/stores/store/Groups"));

const Leads = async(() => import("../src/pages/app/Leads"));


const WeddingplannerLeads = async(() => import("./pages/app/wedding-planner-leads"));

const Mehendileads = async (()=> import("./pages/app/mehendi-leads"));

const Makeupleads = async (()=> import("./pages/app/makeup-leads"));

const Entertainers = async (()=> import("./pages/app/entertainers/entertainers"));

const EditEntertainer = async (()=> import("./pages/app/entertainers/EditEntertainers"));

const BlackList = async (()=> import("./pages/app/Blacklist"));

const MakeupArtists = async (()=> import("./pages/app/makeup/Makeup"));

const routes = [
  {
    path: "/",
    // element: <PresentationLayout />,
    // children: [
    //   {
    //     path: "",
    //     element: <Landing />,
    //   },
    // ],
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <SignIn />,
      },
    ],
  },

  {
    path: "private",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <ProtectedPage />,
      },
    ],
  },
  //Application Routes

  {
    path: "logout",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <NavbarUserDropdown />,
      },
    ],
  },
  {
    path: "store",
    element: <DashboardLayout />,
    children: [
      {
        path: "all",
        element: <Stores />,
      },
      {
        path: "add",
        element: <AddStore />,
      },
      {
        path: "edit/:id",
        element: <EditStore />,
      },
      {
        path: "contacts/:id",
        element: <Contacts />,
      },
      {
        path: "products",
        children: [
          {
            path: "",
            element: <Products />,
          },
          // {
          //   path: ":id",
          //   element: <Products />,
          // },
          {
            path: "add",
            element: <AddProducts />,
          },
        ],
      },
      {
        path: "coupons",
        element: <CouponsList />,
      },
      {
        path: "dashboard",
        element: <StoreDashBoard />,
      },
      {
        path: "tracking",
        element: <Tracking />,
      },
      {
        path: "appointments",
        element: <Appointments />,
      },
      {
        path: "groups",
        element: <Groups />,
      },
    ],
  },
  {
    path: "category",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Categories />,
      },
      {
        path: "add",
        element: <AddNewCategory />,
      },
    ],
  },
  {
    path: "photographers",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Photographers />,
      },
      {
        path: "edit/:id/:slug",
        element: <EditPhotographers />,
      },
      {
        path: "dashboard",
        element: <PhotographerDashboard />,
      },
      {
        path: "leads",
        element: <PhotographerLeads />,
      },
      // {
      //   path: "call_requests",
      //   element: <PhotographerCallRequests />,
      // },
      // {
      //   path: "message_requests",
      //   element: <PhotographerMessageRequests />,
      // },
      {
        path: "quotations",
        element: <PhotographerQuotations />,
      },
      {
        path: "orders",
        element: <PhotographerPaidusers />,
      },
      {
        path: "order-details/:id",
        element: <PhotographerPaiduserDetails />,
      },
      {
        path: "quote-details/:id",
        element: <PhotographerQuoteDetails />,
      }
    ],
  },
  {
    path: "entertainers",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Entertainers />,
      },
      {
        path: "edit/:id",
        element: <EditEntertainer />,
      },
    ],
  },
  {
    path: "makeup-artists",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <MakeupArtists />,
      },
      // {
      //   path: "edit/:id",
      //   element: <EditEntertainer />,
      // },
    ],
  },
  {
    path: "list",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <VendorList />,
      },
    ],
  },
  {
    path: "blacklist",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <BlackList />,
      },
    ],
  },

  {
    path: "users",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Users />,
      },
    ],
  },
  {
    path: "leads",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Leads />,
      },
    ],
  },

  {
    path: "wedding-planner-leads",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <WeddingplannerLeads />,
      },
    ],
  },
  {
    path: "mehendi-leads",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Mehendileads />,
      },
    ],
  },
  {
    path: "makeup-leads",
    element: <DashboardLayout />,
    children: [
      {
        path: "",
        element: <Makeupleads />,
      },
    ],
  },

  {
    path: "lms",
    element: <DashboardLayout />,
    children: [
      {
        path: "req",
        // element: <DashboardLayout />,
        children: [
          // {
          //   path: "create-planner",
          //   element: <CreateProfile />,
          // },
          {
            path: "add-new",
            element: <AddNewReq />,
          },
          {
            path: ":id/client-contact-details",
            element: <ClientContactDetails />,
          },
          {
            path: ":id/additional-details",
            element: <AdditionalDetails />,
          },
          {
            path: ":id/verify",
            element: <VerifyRequirement />,
          },
          {
            path: ":id/edit",
            element: <EditRequirement />,
          },
          // {
          //   path: "show-req/:id",
          //   element: <ShowRequirement />,
          // },
          {
            path: "all",
            element: <AllRequirements />,
          },
          {
            path: ":id/view-req",
            element: <ViewRequirement />,
          },
        ],
      },
      {
        path: "planners",
        // element: <DashboardLayout />,
        children: [
          {
            path: "all",
            element: <ShowAllPlanners />,
          },
          {
            path: "edit/:id",
            element: <ViewAndEditPlanner />,
          },
          {
            path: "add",
            element: <CreateProfile />,
          },
        ],
      },
      {
        path: "leads",
        // element: <DashboardLayout />,
        children: [
          {
            path: "all",
            element: <AllLeads />,
          },
          {
            path: "view/:id",
            element: <ViewAndEditPlanner />,
          },
        ],
      },
    ],
  },
  {
    path: "vendor",
    element: <AuthLayout />,
    children: [
      {
        path: "",
        element: <VendorSignIn />,
      },
      {
        path: "signin",
        element: <VendorSignIn />,
      },
    ],
  },
  {
    path: "vendor",
    element: <DashboardLayout sidebarItems={vendorItems} isVendor={true} />,
    children: [
      {
        path: "info",
        element: <VendorInfo />,
      },
      {
        path: "verify-coupon",
        element: <VendorCoupon />,
      },
      {
        path: "t&c",
        element: <VendorTandC />,
      },
      {
        path: "logout",
        element: <VendorLogout />,
      },
    ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];

export default routes;
