import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./slices/counter";
import storeReducer from "./slices/stores";
import addStoreReducer from "./slices/addStore";
import catsReducer from "./slices/categories";
import contactsReducer from "./slices/contacts";
import cashbackReducer from "./slices/cashback";
import editStoreReducer from "./slices/editStore";
import addContactReducer from "./slices/addContacts";
import editContactReducer from "./slices/editContact";
import dashboardReducer from "./slices/dashboard";
import addStoreImageReducer from "./slices/addImage";
import storeImageReducer from "./slices/storeImages";
import storeDocumentReducer from "./slices/storeDocuments";
import addStoreDocumentReducer from "./slices/addDocument";
import createPlannerReducer from "./slices/planners/createPlanner";
import editPlannerReducer from "./slices/planners/editPlanner";
import plannerReducer from "./slices/planners/planner";
import vendorLoginReducer from "./slices/vendors/vendorLogin";
import vendorInfoReducer from "./slices/vendors/vendorInfo";
import trackingDetailsReducer from "./slices/tracking";

import addRequirementReducer from "./slices/requirements/addRequirement";
import requirementsReducer from "./slices/requirements/requirements";
import editRequirementReducer from "./slices/requirements/editRequirement";
// import leadsReducer from "./slices/leads/leads";
import usersReducer from "./slices/users/users";
import couponsReducer from "./slices/coupons";
import searchReducer from "./slices/filterStore";
import storeGroupReducer from "./slices/storeGroups";
import createCashbackReducer from "./slices/addCashback";
import getAppointmentsReducer from "./slices/appointments";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    store: storeReducer,
    addStore: addStoreReducer,
    categories: catsReducer,
    contacts: contactsReducer,
    addContact: addContactReducer,
    editContact: editContactReducer,
    editStore: editStoreReducer,
    dashboard: dashboardReducer,
    cashback: cashbackReducer,
    addImage: addStoreImageReducer,
    storeImage: storeImageReducer,
    storeDocument: storeDocumentReducer,
    addDocument: addStoreDocumentReducer,
    createPlanner: createPlannerReducer,
    editPlanner: editPlannerReducer,
    planner: plannerReducer,
    vendor: vendorLoginReducer,
    vendorInfo: vendorInfoReducer,
    addNewReq: addRequirementReducer,
    requirements: requirementsReducer,
    editReq: editRequirementReducer,
    trackingDetails: trackingDetailsReducer,
    // leads: leadsReducer,
    users: usersReducer,
    coupons: couponsReducer,
    searchStore: searchReducer,
    storeGroup: storeGroupReducer,
    addCashback: createCashbackReducer,
    appointments: getAppointmentsReducer,
  },
});
