import React, { useState } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
// import { useSelector, useDispatch } from "react-redux";
import { spacing } from "@mui/system";
import {
  Button,
  Grid,
  Paper as MuiPaper,
  Table,
  Card as MuiCard,
  Typography,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  DialogActions,
} from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";
const Card = styled(MuiCard)(spacing);
const Paper = styled(MuiPaper)`
  padding: 5%;
`;

let id = 0;
function createData(storeId, image, title, price, status, storeName) {
  id += 1;
  return { id, storeId, image, title, price, status, storeName };
}

const rows = [
  createData(
    123,
    "https://media.istockphoto.com/photos/abstract-graphic-world-map-illustration-on-blue-background-big-data-picture-id1294021851?s=612x612",
    "Product Title",
    100,
    "Draft",
    "StoreName"
  ),
  createData(
    123,
    "https://media.istockphoto.com/photos/abstract-graphic-world-map-illustration-on-blue-background-big-data-picture-id1294021851?s=612x612",
    "Product Title",
    100,
    "Draft",
    "StoreName"
  ),
  createData(
    123,
    "https://media.istockphoto.com/photos/abstract-graphic-world-map-illustration-on-blue-background-big-data-picture-id1294021851?s=612x612",
    "Product Title",
    100,
    "Draft",
    "StoreName"
  ),
  createData(
    123,
    "https://media.istockphoto.com/photos/abstract-graphic-world-map-illustration-on-blue-background-big-data-picture-id1294021851?s=612x612",
    "Product Title",
    100,
    "Draft",
    "StoreName"
  ),
  createData(
    123,
    "https://media.istockphoto.com/photos/abstract-graphic-world-map-illustration-on-blue-background-big-data-picture-id1294021851?s=612x612",
    "Product Title",
    100,
    "Draft",
    "StoreName"
  ),
];

const statusData = ["Draft", "Active", "Live"];
function Products() {
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  return (
    <React.Fragment>
      <Helmet title="Contacts" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Products
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "10px",
              }}
            >
              <Button variant="contained" onClick={handleClickOpen}>
                Filter Products
              </Button>
              <Link to="add" rel="noopener noreferrer">
                <Button variant="contained" color="primary">
                  <AddIcon />
                  Create Product
                </Button>
              </Link>
            </div>
            <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogTitle>Filter Products</DialogTitle>
              <DialogContent>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                    width: "550px",
                  }}
                >
                  <TextField
                    id="searchByTitle"
                    label="Search by Title"
                    variant="outlined"
                    fullWidth
                    rows={4}
                    my={2}
                    //   onChange={(e) => dispatch(setName(e.target.value))}
                  />
                  <TextField
                    id="searchByStoreName"
                    label="Search by Store Name"
                    variant="outlined"
                    rows={4}
                    fullWidth
                    my={2}
                    //   onChange={(e) => dispatch(setEmail(e.target.value))}
                  />
                  <TextField
                    id="searchById"
                    label="Search by Id"
                    variant="outlined"
                    // style={{ width: "80%" }}
                    rows={4}
                    fullWidth
                    my={2}
                    //   onChange={(e) => dispatch(setPhone(e.target.value))}
                  />
                  <Select
                    value={statusData[0]}
                    fullWidth
                    // onChange={(e) => handleFormValuesChange(e, "textFont")}
                  >
                    {statusData.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button
                  onClick={() => {
                    alert("Filter");
                    setOpen(false);
                  }}
                >
                  Filter Products
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={9} style={{ marginTop: "-10px" }}>
        <Grid item lg={8} xs={12}>
          <Card mb={10}>
            <Paper>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> Id</TableCell>
                    <TableCell>Image</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Store Name</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {row.storeId}
                      </TableCell>
                      <TableCell>
                        <img
                          src={row.image}
                          alt={row.title}
                          style={{ width: "180px", height: "180px" }}
                        />
                      </TableCell>
                      <TableCell>
                        <Button>{row.title}</Button>
                      </TableCell>
                      <TableCell>{row.price}</TableCell>
                      <TableCell>{row.status}</TableCell>
                      <TableCell>
                        <Button>{row.storeName}</Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Paper>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

//Number of Stores and Products on initial page load to be fetched from api.

export default Products;
