import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../../config";

const initialState = {
  data: {},
  message: "",
  loading: false,
};

export const requirementDetails = createAsyncThunk(
  "lms/show-req",
  async (id) => {
    let headers = {
      Authorization: window.localStorage.accessToken,
    };

    return fetch(`${wedlyEnv.baseUrl}/admin/lms/requirements/${id}`, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        console.log("requirements");
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log("=======hello====");
        console.log(error.message);
        return error;
      }
    );
  }
);

export const editRequirementslice = createSlice({
  name: "editStore",
  initialState,
  reducers: {},
  extraReducers: {
    [requirementDetails.fulfilled]: (state, action) => {
      console.log(action.payload.data);
      state.data = action.payload.data;
      state.message = "Requirement Details";
      state.loading = false;
    },
    [requirementDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [requirementDetails.rejected]: (state, action) => {
      state.message = "Failed to Fetch Requirement";
      state.loading = false;
    },
  },
});

export default editRequirementslice.reducer;
