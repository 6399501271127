import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

const initialState = {};

let headers = {
  Authorization: window.localStorage.accessToken,
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const createStoreDocument = createAsyncThunk(
  "stores/create-document",
  async (data = {}) => {
    const formData = new FormData();
    console.log("hi", data);
    formData.append("doc_type", data.doc_type);
    formData.append("file", data.file);
    formData.append("name", data.name);
    formData.append("store_id", data.store_id);

    let url = `${wedlyEnv.baseUrl}/admin/store_documents`;

    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const addStoreDocSlice = createSlice({
  name: "addStoreDoc",
  initialState,
  reducers: {},
  extraReducers: {
    [createStoreDocument.fulfilled]: (state, action) => {
      state.message = "Store Image added sucsessfully";
      state.loading = false;
    },
    [createStoreDocument.pending]: (state, action) => {
      state.loading = true;
    },
    [createStoreDocument.rejected]: (state, action) => {
      state.message = "Failed to Add Image";
      state.loading = false;
    },
  },
});

export default addStoreDocSlice.reducer;
