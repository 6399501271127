import React, { useState } from "react";
import {
  TextField as MuiTextField,
  Button,
  Alert as MuiAlert,
} from "@mui/material";
// import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { spacing } from "@mui/system";
import * as Yup from "yup";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import { wedlyEnv } from "../../config";

const TextField = styled(MuiTextField)(spacing);
const Alert = styled(MuiAlert)(spacing);

function OTPSignIn() {
  // const navigate = useNavigate();
  // ${wedlyEnv.baseUrl}/

  const [isOTPSent, setIsOTPSent] = useState(false);
  const [otp, setOtp] = useState();
  const [isEditMode, setIsEditMode] = useState(false);
  const { otpSignIn } = useAuth();

  const getOtp = async (phoneNum) => {
    let _otp = "";
    try {
      const otpObj = await axios.get(
        `${wedlyEnv.baseUrl}/login/get_otp?phone_no=${phoneNum}&user_type=admin` // only for store
      );
      console.log(otpObj);

      if (!otpObj.data.success) throw new Error(otpObj.data.message);

      setIsOTPSent(true);

      if (otpObj.data.otp) {
        setOtp(otpObj.data.otp.toString());
        _otp = otpObj.data.otp.toString();
      }
      //OTP sent success
    } catch (error) {
      alert(error);
      console.log(error);
      //OTP sent fail
    }
    return _otp;
  };

  return (
    <Formik
      initialValues={{
        phone: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        phone: Yup.number()
          .max(10000000000)
          .required("Phone number is required"),
        password:
          isOTPSent && Yup.number().max(1000000).required("OTP is required"),
      })}
      onSubmit={async (
        values,

        { setErrors, setStatus, setSubmitting, setFieldValue }
      ) => {
        if (isOTPSent) {
          // if (values.password !== otp) {
          //   const message = "Something went wrong";

          //   setStatus({ success: false });
          //   setErrors({ submit: message });
          //   setSubmitting(false);
          // }

          try {
            await otpSignIn(Number(values.phone), Number(values.password)).then(
              () => {
                // navigate("/store/dashboard");
                window.location.pathname = "/leads";
              }
            );
          } catch (error) {
            alert(error);
            console.log(error);
          }
        } else {
          const _otp = await getOtp(Number(values.phone));
          // console.log(_otp);
          if (_otp !== "") {
            setFieldValue("password", _otp);
          }
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => {
        const editPhoneHandler = async () => {
          if (isEditMode) {
            try {
              await getOtp(values.phone);
            } catch (error) {
              console.log(error);
            } finally {
              setIsEditMode(false);
            }
          } else {
            setIsEditMode(true);
          }
        };
        // const setOtpHandler = () => {};
        return (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={3} severity="warning">
                {errors.submit}
              </Alert>
            )}
            {!isOTPSent ? (
              <TextField
                style={{ marginTop: "15px" }}
                type="phone"
                name="phone"
                label="Phone Number"
                value={values.phone}
                onBlur={handleBlur}
                onChange={handleChange}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
                fullWidth
              />
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  {isEditMode ? (
                    <TextField
                      type="phone"
                      name="phone"
                      label="Phone Number"
                      value={values.phone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.phone && errors.phone)}
                      helperText={touched.phone && errors.phone}
                    />
                  ) : (
                    <span style={{ marginLeft: "5px" }}>{values.phone}</span>
                  )}

                  <Button
                    style={{ marginLeft: "20px" }}
                    variant="contained"
                    color="primary"
                    onClick={editPhoneHandler}
                  >
                    {isEditMode ? "Get OTP" : "Edit"}
                  </Button>
                </div>
                <TextField
                  style={{ marginTop: "15px" }}
                  name="password"
                  label="OTP"
                  value={values.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  fullWidth
                />
              </>
            )}
            <Button
              style={{ marginTop: "15px" }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              {isOTPSent ? "Sign In" : "Get OTP"}
            </Button>
          </form>
        );
      }}
    </Formik>
  );
}

export default OTPSignIn;
