import { LogOut, Grid, Users } from "react-feather";

const mainMenu = [
  {
    href: "/vendor/info",
    icon: Grid,
    title: "Vendor Info",
  },
  {
    href: "/vendor/verify-coupon",
    icon: Users,
    title: "Vendor Coupon",
  },
  {
    href: "/vendor/logout",
    icon: LogOut,
    title: "Logout",
  },
];
const navItems = [
  {
    title: "Vendor",
    pages: mainMenu,
  },
];

export default navItems;
