import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";

// import { Link } from "react-router-dom";
import {
  // Grid,
  Card as MuiCard,
  // Paper as MuiPaper,
  Table,
  TableContainer,
  Chip,
  TableBody,
  TableCell,
  TableHead,
  Tooltip,
  TableRow,
  Button,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
  IconButton as MuiIconButton,
  TextField,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Typography,
  Grid
  // Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { spacing } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteStore,
  sendLinkToVendor,
  updateStoreFeaturedFlag
} from "../../../../redux/slices/editStore";
import { deteleStoreLocally, getSingleStoreStats } from "../../../../redux/slices/stores";
import EditIcon from "@mui/icons-material/Edit";
import StarRateIcon from '@mui/icons-material/StarRate';
import PaginationComponent from '../../pagination';
import Pagination from '@mui/material/Pagination';
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EmailIcon from "@mui/icons-material/Email";
import { Copy } from "react-feather";
import { getStores, duplicateStore } from "../../../../redux/slices/stores";
import { QueryStats } from "@mui/icons-material";
const Card = styled(MuiCard)(spacing);
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;
// const Paper = styled(MuiPaper)(spacing);

function SimpleTableDemo() {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStores());
  }, [dispatch]);

  const { records, pageNum, totalPages, perPage } = useSelector(
    (state) => state.store
  );

  const { search, city, category, status } = useSelector(
    (state) => state.searchStore
  );
  // console.log(records, pageNum, totalPages, perPage);

  const [open, setOpen] = useState(false);
  const nagivate = useNavigate();
  // const [type, setType] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const [singleStoreStats, setSingleStoreStats] = useState({});

  const [jumpPage, setJumpPage] = useState("");

  const handleJumpPageChange = (event) => {
    setJumpPage(event.target.value);
  };


  const handleJumpToPage = () => {
    const newPageNum = parseInt(jumpPage, 10) - 1;
    if (newPageNum >= 0 && newPageNum < totalPages) {
      // setPageNum(newPageNum);
      dispatch(getStores({ page: newPageNum +1 ,search, city, category, status}))
        .unwrap()
        .then((result) => {
          // photographerslist(result.data);
        })
        .catch((error) => {
          console.log(error);
          // handle error
        });
    } else {
      alert("Invalid page number");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleJumpToPage();
    }
  };

  const DeleteHandler = (id) => {
    dispatch(deleteStore(id))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult);

        if (originalPromiseResult.message === "Deleted Succesfully") {
          dispatch(deteleStoreLocally(id));
        }
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here

        //console.log("Error");
        console.log(rejectedValueOrSerializedError);
      });
  };
  const getChipColour = (status) => {
    if (status === "live") {
      return "success";
    } else if (status === "draft") {
      return "error";
    } else if (status === "Approved by Vendor") {
      return "info";
    } else {
      return "warning";
    }
  };
  const handleChangePage = (event, newPage) => {
    dispatch(getStores({ page: newPage, search, city, category, status }))
      .unwrap()
      .then((originalPromiseResult) => {
        // dispatch(setPage(newPage));
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here

        //console.log("Error");
        console.log(rejectedValueOrSerializedError);
      });
  };
  const HandleSendToVendor = (row) => {
    alert(row.id);
    dispatch(sendLinkToVendor(row.id))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult);
        alert("Sent to Vendor");
        dispatch(getStores({ page: null, search, city, category, status }));
      })
      .catch((rejectedValueOrSerializedError) => {
        //console.log("Error");
        console.log(rejectedValueOrSerializedError);
      });
  };
  
  const handleUpdateFeaturedFlag = (storeId, isFeatured) => {
    dispatch(updateStoreFeaturedFlag({storeId, isFeatured}))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult);
        dispatch(getStores({ page: pageNum, search, city, category, status }));
      })
      .catch((rejectedValueOrSerializedError) => {
        console.log(rejectedValueOrSerializedError);
      });
  }

  const handleGetSingleStoreStats = (row) => {
    console.log(row.id);
    dispatch(getSingleStoreStats(row.id))
      .then(res=>{
        // console.log(res.payload.data);
        setSingleStoreStats(res.payload.data)
      })
  }

  const [openDupPopUp, setOpenDupPopUp] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [existingGroup, setExistingGroup] = useState("");
  const [storeStats, setStoreStats] = useState({});
  // const handleDuplicateDialog = (id, name, groupId) => {
  //   dispatch(duplicateStore({ storeId: id, name, groupId }))
  //     .unwrap()
  //     .then((originalPromiseResult) => {
  //       console.log(originalPromiseResult);
  //       alert("Store Duplicated");
  //       dispatch(getStores({ page: null, search, city, category, status }));
  //     })
  //     .catch((rejectedValueOrSerializedError) => {
  //       //console.log("Error");
  //       console.log(rejectedValueOrSerializedError);
  //     });

  //   setSelectedRow(null);
  //   setGroupName("");
  //   setExistingGroup("");
  //   setOpenDupPopUp(false);
  // };

  const handleDuplicateDialog = (id, name, groupId) => {
    dispatch(duplicateStore({ storeId: id, name, groupId }))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult);
        alert("Store Duplicated");
        dispatch(getStores({ page: null, search, city, category, status }));
      })
      .catch((rejectedValueOrSerializedError) => {
        //console.log("Error");
        console.log(rejectedValueOrSerializedError);
      });

    setSelectedRow(null);
    setGroupName("");
    setExistingGroup("");
    setOpenDupPopUp(false);
  };

  // console.log(row.categories);
  return (
    <Card mb={10}>
      <Dialog open={openDupPopUp} onClose={() => setOpenDupPopUp(false)}>
        <DialogTitle>Store Statistics</DialogTitle>
        <DialogContent>
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              rowGap: "10px",
              width: "350px",
            }}
          >
            {/* {JSON.stringify(selectedRow)} */}
            {selectedRow && !selectedRow?.store_groups?.length && (
              <>
              <Grid container style={{marginTop:'10px'}}>
                <Grid item xs={6} style={{marginBottom:'10px'}}>
              <Card variant="outlined" style={{background:'#9b217220',padding:'10px', marginRight:'8px'}}>
                <Typography variant="h7" gutterBottom display="inline">
                  Total Appointments
                </Typography> <br></br>
                <Typography variant="h5" gutterBottom display="inline">
                {singleStoreStats.total_appointments}
                </Typography>
              </Card>
              </Grid>
              <Grid item xs={6}>
              <Card variant="outlined" style={{background:'#afc0fa', padding:'10px', marginRight:'8px'}}>
                <Typography variant="h7" gutterBottom display="inline">
                  Total Coupons
                </Typography> <br></br>
                <Typography variant="h5" gutterBottom display="inline" >
                {singleStoreStats.total_coupons}
                </Typography>
              </Card>
              </Grid>
              <Grid item xs={6}>
              <Card variant="outlined" style={{background:'#f5beb3',padding:'10px', marginRight:'8px'}}>
                <Typography variant="h7" gutterBottom display="inline">
                  Total Invoice Amount
                </Typography> <br></br>
                <Typography variant="h5" gutterBottom display="inline">
                {singleStoreStats.total_invoice_amount}
                </Typography>
              </Card>
              </Grid>
              <Grid item xs={6}>
              <Card variant="outlined" style={{background:'#e3cd85', padding:'10px', marginRight:'8px'}}>
                <Typography variant="h7" gutterBottom display="inline">
                  Total Cashback Amount
                </Typography> <br></br>
                <Typography variant="h5" gutterBottom display="inline">
                {singleStoreStats.total_cashback_amount}
                </Typography>
              </Card>
              </Grid>
            </Grid>
                {/* <TextField
                  id="groupName"
                  label="Enter new group name"
                  variant="outlined"
                  fullWidth
                  rows={4}
                  my={2}
                  onChange={(e) => setGroupName(e.target.value)}
                />
                <Button
                  onClick={() => {
                    if (groupName === "") {
                      alert("Group Name shouldnt be empty");
                      return;
                    }
                    handleDuplicateDialog(selectedRow.id, groupName, undefined);
                  }}
                >
                  Duplicate
                </Button> */}

              </>
            )}
            {!!selectedRow?.store_groups?.length && (
              <>
                {/* <TextField
                  id="groupName"
                  label="Enter new group name"
                  variant="outlined"
                  fullWidth
                  rows={4}
                  my={2}
                  onChange={(e) => setGroupName(e.target.value)}
                />
                <Button
                  onClick={() => {
                    if (groupName === "") {
                      alert("Group Name shouldnt be empty");
                      return;
                    }
                    handleDuplicateDialog(selectedRow.id, groupName, undefined);
                  }}
                >
                  Duplicate
                </Button>

                <span style={{ margin: "0 auto" }}>(or)</span> */}
                {/* <FormControl
                  sx={{ mt: 2, mb: 2, minWidth: 120, width: "100%" }}
                >
                  <InputLabel id="demo-simple-select-helper-label">
                    Store Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Store Group"
                    value={existingGroup}
                    onChange={(e) => setExistingGroup(e.target.value)}
                  >
                    {selectedRow?.store_groups.map((item, idx) => (
                      <MenuItem value={item.id} key={idx}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  onClick={() => {
                    if (existingGroup === "") {
                      alert("Select a group to continue");
                      return;
                    }
                    handleDuplicateDialog(
                      selectedRow.id,
                      undefined,
                      existingGroup
                    );
                  }}
                >
                  Duplicate
                </Button> */}
              </>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setSelectedRow(null);
              setOpenDupPopUp(false);
            }}
          >
            Close
          </Button>
          {/* <Button
            onClick={() => {
              alert("Duplicate Store");
              setOpenDupPopUp(false);
            }}
          >
            Duplicate
          </Button> */}
        </DialogActions>
      </Dialog>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>Delete Store Details</DialogTitle>
            <DialogContent>
              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "10px",
                  width: "550px",
                }}
              >
                Need to delete store details{" "}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              <Button
                onClick={() => {
                  DeleteHandler(selectedRow.id);
                  setOpen(false);
                }}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
          <TableHead>
            <TableRow>
              <TableCell>Images</TableCell>
              <TableCell>Store Name</TableCell>
              <TableCell>City</TableCell>
              <TableCell>Category Name</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Approved By</TableCell>
              <TableCell>Commission/Cashback(%)</TableCell>
              <TableCell>Actions</TableCell>
              {/* <TableCell>Verification</TableCell> */}
              {/* <TableCell>Products</TableCell> */}
            </TableRow>
          </TableHead>

          <TableBody>
            {records &&
              records.map((row, idx) => {
                // console.log(row.categories);
                return (
                  <TableRow key={idx}>
                    <TableCell>
                      {row.logo ? (
                        <img
                          src={row.logo}
                          alt={row.name}
                          style={{ width: "50px", height: "50px" }}
                        />
                      ) : (
                        ""
                      )}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {/* {row.id}  */}
                      {row.name ? row.name : ""}
                    </TableCell>
                    <TableCell>
                      {row?.city?.name ? row.city.name : ""}
                    </TableCell>
                    <TableCell>
                      {row.categories.map((x) => x.name).join(",")}
                    </TableCell>

                    <TableCell style={{ textTransform: "capitalize" }}>
                      <Stack direction="column" spacing={1}>
                        <Chip
                          size="small"
                          label={row.status}
                          color={getChipColour(row.status)}
                        />
                        {row.is_featured && <Chip
                          size="small"
                          label="Featured"
                          color="primary"
                        /> }
                      </Stack>
                    </TableCell>
                    <TableCell>
                      {row.t_c_approved_vendor?.name
                        ? row.t_c_approved_vendor?.name
                        : "-"}
                    </TableCell>
                    <TableCell>
                      {row.commission} Comm /{row.cashback} C
                    </TableCell>
                    <TableCell>
                      <Tooltip title={row.is_featured ? "Remove as featured" : "Mark as featured"}>
                          <IconButton color={row.is_featured ? "error" : "inherit"} onClick={() => {
                            handleUpdateFeaturedFlag(row.id, row.is_featured);
                          }} disabled={ row.status === "live" ? false : true }>
                            <StarRateIcon size="11px" />
                        </IconButton>
                      </Tooltip> 
                      <Tooltip title="Edit">
                        <IconButton
                          color="inherit"
                          aria-label="Open drawer"
                          onClick={() => {
                            setSelectedRow(row);
                            nagivate(`/store/edit/${row.id}`);
                          }}
                        >
                          <EditIcon size="11px" />
                        </IconButton>
                      </Tooltip>

                      <Tooltip title="Delete">
                        <IconButton
                          color="inherit"
                          style={{ fontSize: "11px" }}
                          onClick={() => {
                            setSelectedRow(row);
                            setOpen(true);
                          }}
                        >
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </Tooltip>

                      {row.status === "draft" && (
                        <Tooltip title="Send Verification Link">
                          <IconButton
                            color="inherit"
                            style={{ fontSize: "11px" }}
                            onClick={() => {
                              setSelectedRow(row);
                              HandleSendToVendor(row);
                            }}
                          >
                            <EmailIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip title="Store statistics">
                        <IconButton
                          color="inherit"
                          style={{ fontSize: "11px" }}
                          onClick={() => {
                            // setOpen(true);

                            // duplicate(row.id);
                            // alert("duplicated");
                            setSelectedRow(row);
                            // handleDuplicateDialog(row);
                            handleGetSingleStoreStats(row);
                            setOpenDupPopUp(true);
                            setGroupName(row.name);

                          }}
                        >
                          <QueryStats/>
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    {/* <TableCell>
                  <Link to="/store/products/1">
                    <Button>View</Button>
                  </Link>
                </TableCell> */}
                    {/* <TableCell>
                    {row.status === "live" ? (
                      <Typography variant="subtitle2" color="green">
                        Verified
                      </Typography>
                    ) : true ? (
                      <Tooltip title="Send Verification Link">
                        <IconButton
                          color="inherit"
                          style={{ fontSize: "13px" }}
                        >
                          <EmailIcon />
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Button variant="outlined" color="info">
                        Resend Verification Link
                      </Button>
                    )}
                  </TableCell> */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid>
        <PaginationComponent
          jumpPage={jumpPage}
          handleJumpPageChange={handleJumpPageChange}
          handleKeyPress={handleKeyPress}
          handleJumpToPage={handleJumpToPage}
          totalPages={totalPages}
          pageNum={pageNum}
          handleChangePage={handleChangePage}
          perPage={perPage}
        />
      </Grid>
      {/* <TablePagination
        component="div"
        count={totalPages && perPage ? totalPages * perPage : 0}
        page={pageNum ? pageNum : 0}
        onPageChange={handleChangePage}
        rowsPerPage={perPage ? perPage : 10}
        rowsPerPageOptions={[10]}
        onRowsPerPageChange={() => {}}
      /> */}
    </Card>
  );
}

function StoreTable({ records }) {
  return (
    <React.Fragment>
      <SimpleTableDemo rows={records} />
    </React.Fragment>
  );
}

export default StoreTable;
