import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../../config";

// const wedlyEnv = {
//   baseUrl :'https://api-test.7vachan.com'
// }

const initialState = {
  users: [],
  loading: false,
  pageNum: 0,
  previousPage: null,
  nextPage: 1,
  totalPages: 0,
  perPage: 10,
  pageTypes: [],
  selectedPageType: "",
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const getUsers = createAsyncThunk(
  "getUsers",
  async ({ pageNum = 0 }) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };

    let url = `${wedlyEnv.baseUrl}/admin/users`;
 
    if (pageNum) {
      url += `?page=${pageNum}`;
    }
    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error);
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);


export const gets3link = createAsyncThunk(
  "gets3link",
  async () => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };
    let url = `${wedlyEnv.baseUrl}/admin/users/export-csv`;
    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
}
);

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setPage: (state, action) => {
      console.log(action);
      state.pageNum = action.payload;
    },
    setSelectedPageType: (state, action) => {
      state.selectedPageType = action.payload;
    },
  },

  extraReducers: {
    [getUsers.fulfilled]: (state, action) => {
      state.users = action.payload.data.users;

      state.previousPage = action.payload.data.prev_page
        ? action.payload.prev_page - 1
        : null;
      state.nextPage = action.payload.data.next_page
        ? action.payload.next_page - 1
        : null;
      state.perPage = action.payload.data.count;
      state.totalPages = action.payload.data.total_pages;
      state.pageNum = action.payload.data.current_page - 1;
      state.loading = false;
    },
    [getUsers.pending]: (state, action) => {
      state.loading = true;
    },
    [getUsers.rejected]: (state, action) => {
      console.log('action ', action);
      state.error = action.payload && action.payload.error;
      state.loading = false;
    },
  },
});

export const { setPage, setSelectedPageType } = usersSlice.actions;

export default usersSlice.reducer;
