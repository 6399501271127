import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

// const wedlyEnv = {
//   baseUrl :'https://api-test.7vachan.com'
// }

const initialState = {
  records: [],
  loading: false,
  pageNum: 0,
  previousPage: null,
  nextPage: 1,
  totalPages: 0,
  perPage: 10,
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const getStores = createAsyncThunk(
  "stores/get-stores",
  async (data = {}) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };

    const { page, search, city, category, status, showFeatured } = data;

    let url = `${wedlyEnv.baseUrl}/admin/stores?`;

    console.log(data);
    let searchParArr = [];
    if (page) {
      searchParArr.push(`page=${page}`);
    }

    if (search && search !== "") {
      searchParArr.push(`search=${search}`);
    }

    if (city && city !== "") {
      searchParArr.push(`city_id=${city}`);
    }

    if (category && category !== "") {
      searchParArr.push(`category_ids=${category}`);
    }

    if (status && status !== "") {
      searchParArr.push(`status=${status}`);
    }

    if(showFeatured) {
      searchParArr.push(`is_featured=${showFeatured}`);
    }

    url += searchParArr.join("&");

    console.log(searchParArr);
    console.log(url);
    console.log("Rohit");

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error);
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const duplicateStore = createAsyncThunk(
  "stores/duplicate-store",
  async ({ storeId, name, groupId }) => {
    let headers = {
      Authorization: window.localStorage.accessToken,
    };

    let url = `${wedlyEnv.baseUrl}/admin/stores/${storeId}/duplicate`;

    const formData = new FormData();

    if (groupId) {
      formData.append("store_group_id", groupId);
    } else {
      formData.append("store_group_name", name);
    }

    // console.log(url);

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }

    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error);
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const getSingleStoreStats = createAsyncThunk(
  "stores/single-store-stats",
  async (storeId) => {
    let headers = {
      Authorization: window.localStorage.accessToken,
    };
    let url = `${wedlyEnv.baseUrl}/admin/stores/store_stats/${storeId}`;
    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        // console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error);
        console.log(error.message); //=> String
        return error;
      }
    );
  
  }
);

export const storeSlice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setPage: (state, action) => {
      console.log(action);
      state.pageNum = action.payload;
    },
    deteleStoreLocally: (state, action) => {
      const storeIndex = state.records.findIndex(
        (store) => store.id === action.payload
      );
      state.records.splice(storeIndex, 1);
    },
  },

  extraReducers: {
    [getStores.fulfilled]: (state, action) => {
      // console.log("stores");
      // console.log(action.payload.data);
      state.records = action.payload.data.stores;
      state.previousPage = action.payload.data.prev_page
        ? action.payload.data.prev_page - 1
        : null;
      state.nextPage = action.payload.data.next_page
        ? action.payload.data.next_page - 1
        : null;
      state.perPage = action.payload.data.count;
      state.totalPages = action.payload.data.total_pages;
      state.pageNum = action.payload.data.current_page - 1;
      state.loading = false;
    },
    [getStores.pending]: (state, action) => {
      state.loading = true;
    },
    [getStores.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const getAllStoreStats = createAsyncThunk(
  "stores/all-store-stats",
  async () => {
    let headers = {
      Authorization: window.localStorage.accessToken,
    };
    let url = `${wedlyEnv.baseUrl}/admin/stores/store_stats`;

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error);
        console.log(error.message); //=> String
        return error;
      }
    );
});
export const { setPage, deteleStoreLocally } = storeSlice.actions;

export default storeSlice.reducer;
