import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../../config";

// const wedlyEnv = {
//   baseUrl :'https://api-test.7vachan.com'
// }

const initialState = {
  photographers: [],
  loading: false,
  pageNum: 0,
  previousPage: null,
  nextPage: 1,
  totalPages: 0,
  perPage: 10,
  pageTypes: [],
  selectedPageType: "",
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const getPhotographers = createAsyncThunk(
  "getPhotographers",
  async ({ pageNum , phone , studio , cities , filter }) => {


    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };

    
    let url = `${wedlyEnv.baseUrl}/admin/photographers/list?`;


    let searchParArr = [];


    if (pageNum) {
      searchParArr.push(`page=${pageNum}`)
    }

    if (filter) {
      searchParArr.push(`status=${filter}`)
    }

    if (phone) {
      searchParArr.push(`phone=${phone}`)
    }

    if (studio) {
      searchParArr.push(`brand_name=${studio}`)
    }

    if (cities === undefined) {
    }else if ((Array.isArray(cities)) && (cities.length > 0)){
      searchParArr.push(`city_ids[]=${cities}`)
    }

    url += searchParArr.join("&");


    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error);
        console.log(error.message);
        return error;
      }
    );
  }
);


export const photographerscsv = createAsyncThunk(
  "photographerscsv",
  async () => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };
    let url = `${wedlyEnv.baseUrl}/admin/photographers/export-csv`;
    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
}
);


export const VendorsList = createAsyncThunk(
  "photographerscsv",
  async ({pageNum , search}) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };
    let url = `${wedlyEnv.baseUrl}/admin/vendor_users?`;

    let searchParArr = [];

    // if (data) {
    //   searchParArr.push(`search_query=${data}`)
    // }

    if (pageNum > 0) {
      searchParArr.push(`page=${pageNum}`)
    }

    if (search) {
      searchParArr.push(`search_query=${search}`)
    }

    url += searchParArr.join("&");
    
    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
}
);


export const deleteVendor = createAsyncThunk(
  "getmasterdata",
  async (id) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };

    let url = `${wedlyEnv.baseUrl}/admin/vendor_users/${id}`;
 
    return fetch(url, {
      method: "DELETE",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error);
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const getmasterdata = createAsyncThunk(
  "getmasterdata",
  async () => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };

    let url = `${wedlyEnv.baseUrl}/data/photographers-master`;
 
    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error);
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);





export const photographersSlice = createSlice({
  name: "photographers",
  initialState,
  reducers: {
    setPage: (state, action) => {
      console.log(action);
      state.pageNum = action.payload;
    },
    setSelectedPageType: (state, action) => {
      state.selectedPageType = action.payload;
    },
  },

  extraReducers: {
    [getPhotographers.fulfilled]: (state, action) => {
      state.photographers = action.payload.data.photographers;

      state.previousPage = action.payload.data.prev_page
        ? action.payload.prev_page - 1
        : null;
      state.nextPage = action.payload.data.next_page
        ? action.payload.next_page - 1
        : null;
      state.perPage = action.payload.data.count;
      state.totalPages = action.payload.data.total_pages;
      state.pageNum = action.payload.data.current_page - 1;
      state.loading = false;
    },
    [getPhotographers.pending]: (state, action) => {
      state.loading = true;
    },
    [getPhotographers.rejected]: (state, action) => {
      console.log('action ', action);
      state.error = action.payload && action.payload.error;
      state.loading = false;
    },
  },
});

export const { setPage, setSelectedPageType } = photographersSlice.actions;

export default photographersSlice.reducer;
