import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

// const wedlyEnv = {
//   baseUrl :'https://api-test.7vachan.com'
// }

const initialState = {
  records: [],
  loading: false,
  pageNum: "1",
  addCategory: {
    parent_id: "",
    name: "",
    slug: "",

    // image: "", Chcek how do we add that
  },
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const getCats = createAsyncThunk(
  "cats/get-all",
  async (pageNum = null) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };

    let url = `${wedlyEnv.baseUrl}/admin/categories`;

    if (pageNum) {
      url += `?page=${pageNum}`;
    }

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        // console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const addCategory = createAsyncThunk(
  "cats/add-cat",
  async (data = {}) => {
    // const formData = new FormData({ ...data });

    const formData = new FormData();

    formData.append("name", data.name);
    formData.append("parent_id", data.parent_id);
    formData.append("slug", data.slug);
    formData.append("image", data.image);

    // Image must be file

    let headers = {
      Authorization: window.localStorage.accessToken,
    };

    return fetch(`${wedlyEnv.baseUrl}/admin/categories`, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const catsSlice = createSlice({
  name: "cats",
  initialState,
  reducers: {
    setPage: (state, action) => {
      // console.log(action);
      state.pageNum = action.payload;
    },
  },
  extraReducers: {
    [getCats.fulfilled]: (state, action) => {
      // console.log(action.payload);
      state.records = action.payload.data.categories;
      state.loading = false;
    },
    [getCats.pending]: (state, action) => {
      state.loading = true;
    },
    [getCats.rejected]: (state, action) => {
      console.log(action.payload);
      state.loading = false;
    },
  },
});

export const { setPage } = catsSlice.actions;

export default catsSlice.reducer;
