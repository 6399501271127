import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../../config";

const initialState = {
  data: {
    city: "",
    businessCategory: "",
    name: "",
    companyName: "",
    about: "",
    instagramHandle: "",
    fbHandle: "",
    website: "",
    email: "",
    gstNumber: "",
    alternateNumber: "",
  },
  message: "",
  loading: false,
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const plannerDetails = createAsyncThunk(
  "planners/edit-planner",
  async (id) => {
    let headers = {
      Authorization: window.localStorage.accessToken,
    };

    return fetch(`${wedlyEnv.baseUrl}/admin/lms/planners/${id}`, {
      method: "GET",
      headers,
    }).then((response) => {
      if (response.ok) {
        console.log(response);
        return response.json();
      } else {
        console.log(response);
        throw new Error(response.message);
      }
    });
    // .then((resp) => {
    //   console.log(resp);

    // })
    // .catch((error) => {
    //   console.log(error);
    // });
  }
);
export const editLMSPlanner = createAsyncThunk(
  "lms/edit-planner",
  async (data = {}) => {
    const formData = new FormData();
    console.log(data);

    formData.append("name", data.name);
    formData.append("company_name", data.companyName);
    formData.append("city_id", data.city.id);
    formData.append("business_category_id", data.businessCategory.id);
    formData.append("about", data.about);
    formData.append("id", data.id);
    if (data.alternateNumber !== "") {
      formData.append("alternate_number", data.alternateNumber);
    }

    if (data.instagramHandle !== "") {
      formData.append("insta_handle", data.instagramHandle);
    }
    if (data.fbHandle !== "") {
      formData.append("fb_handle", data.fbHandle);
    }

    if (data.website !== "") {
      formData.append("website", data.website);
    }
    if (data.email !== "") {
      formData.append("email", data.email);
    }

    if (data.gstNumber !== "") {
      formData.append("gst_number", data.gstNumber);
    }

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    let headers = {
      Authorization: window.localStorage.accessToken,
    };
    let url = `${wedlyEnv.baseUrl}/admin/lms/planners`;

    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        console.log(response);
        console.log("Edit");
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const editPlannersSlice = createSlice({
  name: "editPlanner",
  initialState,
  reducers: {
    setCity: (state, action) => {
      console.log(action.payload);
      state.data.city = action.payload;
    },
    setCategory: (state, action) => {
      console.log(action.payload);
      state.data.businessCategory = action.payload;
    },

    setName: (state, action) => {
      console.log(action.payload);
      state.data.name = action.payload;
    },
    setCompanyName: (state, action) => {
      console.log(action.payload);
      state.data.companyName = action.payload;
    },
    setAbout: (state, action) => {
      console.log(action.payload);
      state.data.about = action.payload;
    },
    setInstagramHandle: (state, action) => {
      console.log(action.payload);
      state.data.instagramHandle = action.payload;
    },
    setFBHandle: (state, action) => {
      console.log(action.payload);
      state.data.fbHandle = action.payload;
    },
    setWebsite: (state, action) => {
      console.log(action.payload);
      state.data.website = action.payload;
    },
    setEmail: (state, action) => {
      console.log(action.payload);
      state.data.email = action.payload;
    },
    setAlternateNumber: (state, action) => {
      console.log(action.payload);
      state.data.alternateNumber = action.payload;
    },
    setGSTNumber: (state, action) => {
      console.log(action.payload);
      state.data.gstNumber = action.payload;
    },
    setDefaults: (state, action) => {
      state.data = {
        city: "",
        businessCategory: "",
        name: "",
        companyName: "",
        about: "",
        instagramHandle: "",
        fbHandle: "",
        website: "",
        email: "",
        gstNumber: "",
        alternateNumber: "",
      };
    },
  },
  extraReducers: {
    [plannerDetails.fulfilled]: (state, action) => {
      console.log(action.payload.data);
      //   console.log(action.payload.data?.City);
      state.data = {
        about: action.payload.data?.About,
        name: action.payload.data?.Name,
        city: action.payload.data?.City,
        companyName: action.payload.data?.["Company name"],
        businessCategory: action.payload.data?.["Business category"],
        instagramHandle: action.payload.data?.["Insta handle"],
        fbHandle: action.payload.data?.["Fb handle"],
        website: action.payload.data?.Website,
        gstNumber: action.payload.data?.["GST number"],
        email: action.payload.data?.Email,
        alternateNumber: action.payload.data?.["Alternate number"],
      };
      state.message = "Planner Details";
      state.loading = false;
    },
    [plannerDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [plannerDetails.rejected]: (state, action) => {
      state.message = "Failed to Fetch Planner Details";
      state.loading = false;
    },
  },
});

export const {
  setAbout,
  setCategory,
  setCity,
  setCompanyName,
  setName,
  setAlternateNumber,
  setEmail,
  setInstagramHandle,
  setFBHandle,
  setWebsite,
  setGSTNumber,
  setDefaults,
} = editPlannersSlice.actions;
export default editPlannersSlice.reducer;
