import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

const initialState = {};

let headers = {
  Authorization: window.localStorage.accessToken,
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const createStoreImage = createAsyncThunk(
  "stores/create-image",
  async (data = {}) => {
    const formData = new FormData();
    console.log("hi", data);
    formData.append("store_image", data.store_image);
    formData.append("name", data.name);
    formData.append("store_id", data.store_id);
    formData.append("image_type", data.image_type);

    let url = `${wedlyEnv.baseUrl}/admin/store_images`;

    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const deleteImage = createAsyncThunk(
  "images/delete",
  async (data = {}) => {
    let headers = {
      Authorization: window.localStorage.accessToken,
    };
    const formData = new FormData();
    console.log(data);
    formData.append("store_id", data.store_id);
    formData.append("store_image_ids", data.imageId);
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    return fetch(`${wedlyEnv.baseUrl}/admin/store_images/images_delete`, {
      body: formData,
      method: "DELETE",
      headers,
    }).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        console.log(response);
        throw new Error(response.message);
      }
    });
  }
);

export const addStoreImageSlice = createSlice({
  name: "addStoreImage",
  initialState,
  reducers: {},
  extraReducers: {
    [createStoreImage.fulfilled]: (state, action) => {
      state.message = "Store Image added sucsessfully";
      state.loading = false;
    },
    [createStoreImage.pending]: (state, action) => {
      state.loading = true;
    },
    [createStoreImage.rejected]: (state, action) => {
      state.message = "Failed to Add Image";
      state.loading = false;
    },
  },
});

export default addStoreImageSlice.reducer;
