import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

// const wedlyEnv = {
//   baseUrl :'https://api-test.7vachan.com'
// }


const initialState = {
  records: [],
  loading: false,
  pageNum: 0,
  previousPage: null,
  nextPage: 1,
  totalPages: 0,
  perPage: 10,
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const getAppointments = createAsyncThunk(
  "stores/appointments",
  async (data = {}) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };

    const { page, search, city, status } = data;

    let url = `${wedlyEnv.baseUrl}/admin/store_appointments?`;

    console.log(data);
    let searchParArr = [];
    if (page) {
      searchParArr.push(`page=${page}`);
    }

    if (search && search !== "") {
      searchParArr.push(`store_name=${search}`);
    }

    if (city && city !== "") {
      searchParArr.push(`city_id=${city}`);
    }

    if (status && status !== "") {
      searchParArr.push(`status=${status}`);
    }

    url += searchParArr.join("&");

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error);
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const updateAppointmentStatus = createAsyncThunk(
  "stores/updateAppointmentStatus",
  async (data = {}) => {
    let headers = {
      Authorization: window.localStorage.accessToken,
    };
    console.log(data);
    let url = `${wedlyEnv.baseUrl}/admin/store_appointments/${data.id}/update_status`;
    const formData = new FormData();
    formData.append("status", data.status);

    return fetch(url, {
      body: formData,
      method: "PUT",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error);
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const appointmentsSLice = createSlice({
  name: "store",
  initialState,
  reducers: {
    setPage: (state, action) => {
      console.log(action);
      state.pageNum = action.payload;
    },
    deteleStoreLocally: (state, action) => {
      const storeIndex = state.records.findIndex(
        (store) => store.id === action.payload
      );
      state.records.splice(storeIndex, 1);
    },
  },

  extraReducers: {
    [getAppointments.fulfilled]: (state, action) => {
      // console.log("stores");
      console.log(action.payload);
      state.records = action.payload.data.store_appointments;
      state.previousPage = action.payload?.prev_page
        ? action.payload?.prev_page - 1
        : null;
      state.nextPage = action.payload?.next_page
        ? action.payload.next_page - 1
        : null;
      state.perPage = action.payload.count;
      state.totalPages = action.payload?.total_pages;
      state.pageNum = action.payload?.current_page - 1;
      state.loading = false;
    },
    [getAppointments.pending]: (state, action) => {
      state.loading = true;
    },
    [getAppointments.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { setPage, deteleStoreLocally } = appointmentsSLice.actions;

export default appointmentsSLice.reducer;
