import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

const initialState = {
  search: "",
  category: "",
  city: "",
  status: "",
  page: "",
  loading: false,
  message: "",
  showFeatured: false
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

// export const getSearchResults = createAsyncThunk(
//   "store/search-details",
//   async (data = {}) => {
//     // const formData = new FormData({ ...data });

//     const formData = new FormData();
//     // const outfitTypeIds = data.outfitTypes
//     //   .map((outfitType) => outfitType.value)
//     //   .join(",");
//     const categoryIds = data.activeCategory.map((item) => item.value).join(",");
//     // console.log(outfitTypeIds);

//     console.log(data);
//     console.log("Data Check");

//     if (data.search !== "") {
//       formData.append("search", data.search);
//     }
//     if (categoryIds !== "") {
//       formData.append("category_ids", categoryIds);
//     }

//     if (data.city !== "") {
//       formData.append("city_id", data.activeCity);
//     }

//     formData.append("", data.activeCity);

//     for (var pair of formData.entries()) {
//       console.log(pair[0] + ", " + pair[1]);
//     }

//     let headers = {
//       Authorization: window.localStorage.accessToken,
//     };

//     return fetch(`${wedlyEnv.baseUrl}/admin/stores/${data.id}`, {
//       body: formData,
//       method: "PUT",
//       headers,
//     }).then((response) => {
//       if (response.ok) {
//         return response.json();
//       } else {
//         console.log(response);
//         throw new Error(response.message);
//       }
//     });
//   }
// );

// export const getSpecialities = createAsyncThunk(
//   "stores/get-specialities",
//   async () => {
//     let headers = {
//       Authorization: window.localStorage.accessToken,
//     };
//     return fetch(`${wedlyEnv.baseUrl}/admin/specialities`, {
//       method: "GET",
//       headers,
//     }).then((response) => {
//       if (response.ok) {
//         return response.json();
//       } else {
//         console.log(response);
//         throw new Error(response.message);
//       }
//     });
//   }
// );

export const searchStoreSlice = createSlice({
  name: "searchStore",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setCategory: (state, action) => {
      state.category = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
    setShowFeatured: (state, action) => {
      state.showFeatured = action.payload;
    },

    setDefaults: (state, action) => {
      state = {
        search: "",
        category: "",
        city: "",
        status: "",
        showFeatured: false
      };
    },
  },
});

export const { setSearch, setCity, setCategory, setStatus, setShowFeatured, setDefaults } =
  searchStoreSlice.actions;

export default searchStoreSlice.reducer;
