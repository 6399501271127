import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

const initialState = {
  data: {
    blocksData: [],
    storeStats: [],
    tableData: [],
    userStats: [],
    coupons: [],
  },
  loading: false,
};

let headers = {
  "Content-Type": "application/json",
  Authorization: window.localStorage.accessToken,
};

export const getWeeklySalesLabels = createAsyncThunk(
  "dashboard/get-sales-labels",
  async () => {
    let url = `${wedlyEnv.baseUrl}/admin/stores/dashboard`;

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        // console.log("tfyguhijok");
        // console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    setRecords: (state, action) => {
      // console.log(state.records, action.payload);
      state.records.push(action.payload);
      // console.log(state.records);
    },
  },
  extraReducers: {
    [getWeeklySalesLabels.fulfilled]: (state, action) => {
      state.data.blocksData = action.payload.data[0]?.blocks;
      state.data.storeStats = action.payload.data[0]?.store_stats.map(
        (item) => item[Object.keys(item)]
      );
      state.data.tableData = action.payload.data[0]?.stores;

      state.data.userStats = action.payload.data[0]?.user_stats;
      state.data.coupons = action.payload.data[0]?.coupons;
      state.loading = false;
    },
    [getWeeklySalesLabels.pending]: (state, action) => {
      state.loading = true;
    },
    [getWeeklySalesLabels.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { setRecords } = dashboardSlice.actions;

export default dashboardSlice.reducer;
