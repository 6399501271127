import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Outlet } from "react-router-dom";

import {
  Hidden,
  CssBaseline,
  Paper as MuiPaper,
  IconButton as MuiIconButton,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { spacing } from "@mui/system";
import { Menu as MenuIcon } from "@mui/icons-material";
import GlobalStyle from "../components/GlobalStyle";
// import Navbar from "../components/navbar/Navbar";
import dashboardItems from "../components/sidebar/dashboardItems";
import Sidebar from "../components/sidebar/Sidebar";
// import Footer from "../components/Footer";
// import Settings from "../components/Settings";
import { useNavigate } from "react-router-dom";
const drawerWidth = 258;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;
const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 100%;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

const Dashboard = ({ children, sidebarItems = dashboardItems }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  useEffect(() => {
    const pathname = window.location.pathname;

    const vendorToken = window.localStorage.vendorToken;

    if (pathname.includes("vendor")) {
      if (!vendorToken) {
        navigate("/vendor");
      }
    }
  }, [navigate]);
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {/* <p>ftyguhijo</p> */}
      <Drawer>
        <Hidden lgUp implementation="js">
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            items={sidebarItems}
          />
        </Hidden>

        <Hidden mdDown implementation="css">
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={sidebarItems}
          />
        </Hidden>
      </Drawer>

      <AppContent>
        <Hidden mdUp>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingRight: 12,
            }}
          >
            <IconButton
              aria-label="Open drawer"
              onClick={handleDrawerToggle}
              size="large"
            >
              <MenuIcon style={{ color: "black" }} />
            </IconButton>
          </div>
        </Hidden>
        <MainContent p={isLgUp ? 12 : 5}>
          {children}
          <Outlet />
        </MainContent>
      </AppContent>
    </Root>
  );
};

export default Dashboard;
