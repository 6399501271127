import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../../config";

const initialState = {
  planners: [],
  loading: false,
  pageNum: 0,
  previousPage: null,
  nextPage: 1,
  totalPages: 0,
  perPage: 10,
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const getPlanners = createAsyncThunk(
  "stores/get-stores",
  async (pageNum = null) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };

    let url = `${wedlyEnv.baseUrl}/admin/lms/planners`;

    if (pageNum) {
      url += `?page=${pageNum + 1}`;
    }

    console.log(url);

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error);
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const plannersList = createAsyncThunk("lms/planners", async () => {
  let headers = {
    Authorization: window.localStorage.accessToken,
  };

  return fetch(`${wedlyEnv.baseUrl}/admin/lms/planners/select_list`, {
    method: "GET",
    headers,
  }).then(
    (response) => {
      return response.json();
    },
    (error) => {
      console.log(error.message); //=> String
      return error;
    }
  );
});

export const plannerSlice = createSlice({
  name: "planners",
  initialState,
  reducers: {
    setPage: (state, action) => {
      console.log(action);
      state.pageNum = action.payload;
    },
    //     deteleStoreLocally: (state, action) => {
    //       const storeIndex = state.records.findIndex(
    //         (store) => store.id === action.payload
    //       );
    //       state.records.splice(storeIndex, 1);
    //     },
  },

  extraReducers: {
    [getPlanners.fulfilled]: (state, action) => {
      // console.log(action.payload.data);
      state.planners = action.payload.data;

      state.previousPage = action.payload.prev_page
        ? action.payload.prev_page - 1
        : null;
      state.nextPage = action.payload.next_page
        ? action.payload.next_page - 1
        : null;
      state.perPage = action.payload.count;
      state.totalPages = action.payload.total_pages;
      state.pageNum = action.payload.current_page - 1;
      state.loading = false;
    },
    [getPlanners.pending]: (state, action) => {
      state.loading = true;
    },
    [getPlanners.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});
export const { setPage } = plannerSlice.actions;
export default plannerSlice.reducer;
