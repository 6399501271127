import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../../config";

const initialState = {};

let headers = {
  Authorization: window.localStorage.accessToken,
};
// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const createRequirement = createAsyncThunk(
  "lms/create-requirement",
  async (data = {}) => {
    const formData = new FormData();
    // console.log("requirement", data);
    formData.append("bride_name", data.bride);
    formData.append("groom_name", data.groom);
    formData.append("wedding_dates[][city_id]", data.city);
    formData.append("wedding_dates[][from_date]", data.weddingFromDate);
    formData.append("wedding_dates[][to_date]", data.weddingToDate);
    formData.append("budget", data.budget);
    formData.append("parent_details", data.parentDetails);
    formData.append("planner_id", data.planner);

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    let url = `${wedlyEnv.baseUrl}/admin/lms/requirements/step1_submit`;

    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const addContactDetails = createAsyncThunk(
  "lms/leads/addContactDetails",
  async (data = {}) => {
    const formData = new FormData();
    // console.log("requirement", data);
    formData.append("client_contact_name", data.contactName);
    formData.append("mobile_no", data.mobile);
    formData.append("alternate_mobile_no", data.alternate_num);
    formData.append("id", data.id);

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    let url = `${wedlyEnv.baseUrl}/admin/lms/requirements/step2_submit`;

    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const addAdditionalDetails = createAsyncThunk(
  "lms/leads/addAdditionalDetails",
  async (data = {}) => {
    const formData = new FormData();
    // console.log("requirement", data);
    formData.append("meal_req", data.mealRequirements);
    formData.append("alcohol", data.isAlcohol);
    formData.append("family_background", data.familyBackground);
    formData.append("veg", data.isVeg);
    formData.append("wedly_planning", data.type);
    formData.append("additional_details", data.additionalDetails);
    formData.append("id", data.id);

    // for (var pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    let url = `${wedlyEnv.baseUrl}/admin/lms/requirements/step3_submit`;

    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const addRequirement = createSlice({
  name: "addreq",
  initialState,
  reducers: {},
  extraReducers: {
    [createRequirement.fulfilled]: (state, action) => {
      state.message = "Requirement Added sucsessfully";
      state.loading = false;
    },
    [createRequirement.pending]: (state, action) => {
      state.loading = true;
    },
    [createRequirement.rejected]: (state, action) => {
      state.message = "Failed to Create";
      state.loading = false;
    },
    [addContactDetails.fulfilled]: (state, action) => {
      state.message = "Contact Details Added sucsessfully";
      state.loading = false;
    },
    [addContactDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [addContactDetails.rejected]: (state, action) => {
      state.message = "Failed to Add";
      state.loading = false;
    },
    [addAdditionalDetails.fulfilled]: (state, action) => {
      state.message = "Additional Details Added sucsessfully";
      state.loading = false;
    },
    [addAdditionalDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [addAdditionalDetails.rejected]: (state, action) => {
      state.message = "Failed to Add";
      state.loading = false;
    },
  },
});

export default addRequirement.reducer;
