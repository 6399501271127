import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { getTrackingDetails } from "../../../../redux/slices/tracking";
import { Button, Grid, Paper as MuiPaper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { citiesList } from "../../../../redux/slices/addStore";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
  DialogActions,
  InputLabel,
  FormControl,
  TableCell,
  TableBody,
  Table,
  Card,
  TableRow,
  TextField,
  TableHead,
} from "@mui/material";
import { getCats } from "../../../../redux/slices/categories";
const Paper = styled(MuiPaper)`
  padding: 2%;
`;

// const types = ["type1", "type2"];
// const actions = ["Code Generated", "Code not Generated"];
function Tracking() {
  const dispatch = useDispatch();

  const { trackingRecords } = useSelector((state) => state.trackingDetails);
  const [open, setOpen] = useState(false);
  const [city, setCity] = useState("");
  const [category, setCategory] = useState("");

  const [cities, setCities] = useState([]);
  const [categories, setCategories] = useState([]);

  const [filterArr, setFilterArray] = useState({});

  useEffect(() => {
    dispatch(getTrackingDetails());

    dispatch(citiesList())
      .unwrap()
      .then((originalPromiseResult) => {
        setCities(originalPromiseResult.cities);
        // handle result here
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here
        // setLoadingCitiesError(true);
        //console.log("Error");
        console.log(rejectedValueOrSerializedError);
      });

    dispatch(getCats())
      .unwrap()
      .then((originalPromiseResult) => {
        setCategories(
          originalPromiseResult.data.categories.map((record) => {
            return {
              id: record.id,
              name: record.name,
            };
          })
        );
        // handle result here
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here

        //console.log("Error");
        console.log(rejectedValueOrSerializedError);
      });
  }, [dispatch]);

  const handleCity = (e) => {
    setCity(e.target.value);
    setFilterArray({ ...filterArr, city_id: e.target.value });
  };
  const handleCategory = (e) => {
    setCategory(e.target.value);
    setFilterArray({ ...filterArr, category_ids: e.target.value });
  };
  const handleStoreName = (e) => {
    setFilterArray({ ...filterArr, store_name: e.target.value });
  };

  const handleFilterSearch = () => {
    dispatch(getTrackingDetails(filterArr));
    // setFilterArray({});
    setOpen(false);
  };
  console.log(filterArr);
  return (
    <React.Fragment>
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Tracking
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: "15px" }}
              onClick={() => setOpen(true)}
            >
              Filter
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogTitle>Filter Products</DialogTitle>
              <DialogContent>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                    width: "550px",
                  }}
                >
                  <TextField
                    id="storeName"
                    label="Search by Store Name"
                    variant="outlined"
                    fullWidth
                    rows={4}
                    my={2}
                    onChange={handleStoreName}
                  />
                  <FormControl
                    sx={{
                      mt: 1,
                      mb: 1,
                      minWidth: 120,
                      width: "100%",
                    }}
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      Category Name
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Parent Category"
                      value={category}
                      onChange={handleCategory}
                    >
                      {categories.map((category, idx) => (
                        <MenuItem value={category.id} key={idx}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      City
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="City"
                      value={city}
                      // style={{ width: "210px" }}
                      onChange={handleCity}
                    >
                      {cities.map((item, idx) => (
                        <MenuItem value={item.id} key={idx}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={handleFilterSearch}>Filter</Button>
              </DialogActions>
            </Dialog>
            {/* <Link to="/store/add">
              <Button variant="contained" color="primary">
                <AddIcon />
                Create Store
              </Button>
            </Link> */}
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={9} style={{ marginTop: "-10px" }}>
        <Grid item lg={8}>
          <Paper>
            <Card mb={10}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell> User Type</TableCell>
                    <TableCell>Action</TableCell>
                    <TableCell>Value</TableCell>
                    <TableCell>By Whom</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {trackingRecords &&
                    trackingRecords.map((row, idx) => (
                      <TableRow key={idx}>
                        <TableCell component="th" scope="row">
                          {row.user.user_type}
                        </TableCell>
                        <TableCell>{row.action}</TableCell>
                        <TableCell>{row.value}</TableCell>
                        <TableCell>{row.user.phone}</TableCell>
                        <TableCell>{row.created_at}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Card>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Tracking;
