import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { Button, Grid, Paper as MuiPaper, Typography } from "@mui/material";
import { Add as AddIcon } from "@mui/icons-material";

import { getStores } from "../../../../redux/slices/stores";

import StoreTable from "./StoresTables";

import {
  // Pagination,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  DialogActions,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// const statusData = ["Draft", "Sent to Vendor", "Confrimed By Vendor", "Live"];
// const categories = ["cat1", "cat2"];
// const cities = ["city1", "city2"];
import { getCats } from "../../../../redux/slices/categories";
import { citiesList } from "../../../../redux/slices/addStore";

import {
  setSearch,
  setCity,
  setStatus,
} from "../../../../redux/slices/filterStore";
import { getAppointments } from "../../../../redux/slices/appointments";
import AppointmentsTable from "./AppointmentsTable";

const Paper = styled(MuiPaper)`
  padding: 2%;
`;

function Appointments() {
  const [cities, setCities] = useState([]);
  const [open, setOpen] = useState(false);
  const [loadingCitiesError, setLoadingCitiesError] = useState(false);

  const { search, city, status } = useSelector(
    (state) => state.searchStore
  );

  const statusData = ["pending", "confirmed"];

  const dispatch = useDispatch();

  const handleFilter = () => {
    dispatch(getAppointments({ pageNum: null, search, city, status }))
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult);
      })
      .catch((rejectedValueOrSerializedError) => {
        // handle error here

        //console.log("Error");
        console.log(rejectedValueOrSerializedError);
      });
    setOpen(false);
  };

  // const handleClearFilters = () => {
  //   dispatch(setDefaults());
  //   dispatch(getStores());

  //   console.log(
  //     `city:${city}, category:${category}, search:${search}, status:${status}`
  //   );
  //   console.log("Rohiyt657");
  // };

  useEffect(() => {
    dispatch(citiesList())
      .unwrap()
      .then((originalPromiseResult) => {
        console.log(originalPromiseResult);
        // setCitiesJson(originalPromiseResult.cities);
        setCities(originalPromiseResult.cities);
      })
      .catch((rejectedValueOrSerializedError) => {
        setLoadingCitiesError(true);
        //console.log("Error");
        console.log(rejectedValueOrSerializedError);
      });
  }, []);

  if (loadingCitiesError) {
    return <h1>Some Error in loading Cities. Check and Try Again.</h1>;
  }

  return (
    <React.Fragment>
      <Helmet title="Appointments" />
      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Scheduled Appointments
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Button
              variant="contained"
              color="success"
              style={{ marginRight: "15px" }}
              onClick={() => setOpen(true)}
            >
              Filter
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
              <DialogTitle>Filter Appointments</DialogTitle>
              <DialogContent>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "10px",
                    width: "550px",
                  }}
                >
                  <TextField
                    id="storeName"
                    label="Search by Store Name"
                    variant="outlined"
                    fullWidth
                    rows={4}
                    my={2}
                    value={search}
                    onChange={(e) => {
                      dispatch(setSearch(e.target.value));
                    }}
                  />

                  <FormControl sx={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-helper-label">
                      City
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="City"
                      value={city ?? ""}
                      onChange={(e) => {
                        console.log(e);
                        dispatch(setCity(e.target.value));
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {cities.map((item, idx) => (
                        <MenuItem value={item.id} key={idx}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl
                    sx={{ mt: 2, mb: 2, minWidth: 120, width: "100%" }}
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Status"
                      value={status}
                      onChange={(e) => {
                        console.log(e);
                        dispatch(setStatus(e.target.value));
                      }}
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {statusData.map((item, idx) => (
                        <MenuItem value={item} key={idx}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)}>Cancel</Button>
                <Button onClick={handleFilter}>Filter</Button>
              </DialogActions>
            </Dialog>
          </div>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: "10px" }}>
        <Grid item lg={12}>
          <Paper>
            <AppointmentsTable />
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Appointments;
