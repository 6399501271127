import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { VendorsList, deleteVendor } from "../../../redux/slices/photographers/photographers";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import {
  Grid,
  Table,
  Typography,
  TableBody,
  TableCell,
  IconButton as MuiIconButton,
  TableHead,
  TableRow,
  Card as MuiCard,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Box,
  TextField,
  TablePagination,
} from "@mui/material";
import toast from "react-hot-toast";
import PaginationComponent from '../pagination';

const Card = styled(MuiCard)`
  ${(props) => props.theme.spacing}
`;
const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function VendorList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [selectedRow, setSelectedRow] = useState(null);
  const [id, setId] = useState(null);

  const leads = data.vendor_users || [];
  const totalPages = data.total_pages || 0;
  const perPage = 10;
  const pageNum = data.current_page ? data.current_page - 1 : 0;

  const [search , setSearch] = useState('')
  const [jumpPage, setJumpPage] = useState("");


  const handleJumpPageChange = (event) => {
    setJumpPage(event.target.value);
  };
  

  useEffect(() => {
    dispatch(VendorsList({ pageNum: 0 , search:search }))
      .unwrap()
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dispatch, search]);

  const handleChangePage = (event, newPage) => {
    dispatch(VendorsList({ pageNum: newPage }))
      .unwrap()
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleJumpToPage = () => {
    const newPageNum = parseInt(jumpPage, 10) - 1;
    if (newPageNum >= 0 && newPageNum < totalPages) {
      // setPageNum(newPageNum);
      dispatch(VendorsList({ pageNum: newPageNum +1 }))
        .unwrap()
        .then((result) => {
          setData(result.data);
        })
        .catch((error) => {
          console.log(error);
          // handle error
        });
    } else {
      alert("Invalid page number");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleJumpToPage();
    }
  };


  const handleOpen = (id) => {
    setOpen(true);
    setId(id);
  };

  const handleDelete = () => {
    dispatch(deleteVendor(id))
      .unwrap()
      .then((result) => {
        if (result.success) {
          setOpen(false);
          dispatch(VendorsList())
            .unwrap()
            .then((result) => {
              setData(result.data);
            })
            .catch((error) => {
              console.log(error);
            });
            alert("Vendor deleted successfully.");
            window.location.reload()
        } else {
          alert(result.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to delete vendor.");
      });
  };

  return (
    <>
      <Helmet title="Photographers PaidUser List" />

      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle sx={{ textAlign: "center" }}>Confirm Delete</DialogTitle>
        <DialogContent>
          <Box m={10}>
            <Stack direction="column" spacing={3}>
              <Grid container justifyContent="space-between">
                <Grid item md={6}>
                  <Button
                    sx={{
                      backgroundColor: "#631549",
                      color: "#ffffff",
                      fontWeight: "bold",
                      padding: "5px 25px",
                      "&:hover": {
                        color: "#631549",
                        backgroundColor: "#ffffff",
                        border: "1px solid #631549",
                      },
                    }}
                    onClick={() => setOpen(false)}
                  >
                    Deny
                  </Button>
                </Grid>
                <Grid item md={6}>
                  <Button
                    sx={{
                      backgroundColor: "#631549",
                      color: "#ffffff",
                      fontWeight: "bold",
                      padding: "5px 25px",
                      "&:hover": {
                        color: "#631549",
                        backgroundColor: "#ffffff",
                        border: "1px solid #631549",
                      },
                    }}
                    onClick={handleDelete}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>

      <Grid container justifyContent="space-between" spacing={2}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Vendor List
          </Typography>
        </Grid>
        <Grid item>
          <TextField
              id="outlined-basic"
              label="Search"
              variant="outlined"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
        </Grid>
      </Grid>

      {/* <div style={{display:"flex" , justifyContent:'left', paddingTop:'10px'  }}>
      
      <Grid item style={{padding:'10px'}}>
          <Card variant="outlined" style={{ background:'#9b217220',padding:'10px', marginRight:'8px'}}>
            <Typography variant="h7" gutterBottom display="inline">
              Total Count
            </Typography> 
            <Typography variant="h5" gutterBottom display="inline"  style={{marginLeft:'5px'}}>
            22
            </Typography>
          </Card>
        </Grid>
        <Grid  item style={{padding:'10px'}}>
        <Card variant="outlined" style={{ background:'#9b217220',padding:'10px', marginRight:'8px',cursor:'pointer'}} >
            <Typography variant="h7" gutterBottom display="inline">
              Published
            </Typography> 
            <Typography variant="h5" gutterBottom display="inline"  style={{marginLeft:'5px'}}>
            22
            </Typography>
          </Card>
        </Grid> 
      </div> */}

      <Grid container spacing={9} style={{ marginTop: "-10px" }}>
        <Grid item lg={12} xs={12}>
          <Card mb={10}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><h4>Id</h4></TableCell>
                  <TableCell><h4>Name</h4></TableCell>
                  <TableCell><h4>Email</h4></TableCell>
                  <TableCell><h4>Phone</h4></TableCell>
                  <TableCell><h4>UTM</h4></TableCell>
                  <TableCell><h4>Action</h4></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {leads.length > 0 ? (
                  leads.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>{row.id ?? "-"}</TableCell>
                      <TableCell>{row.name ?? "-"}</TableCell>
                      <TableCell>{row.email ?? "-"}</TableCell>
                      <TableCell>{row.phone_no ?? "-"}</TableCell>
                      <TableCell>{row.utm_source === "WA" ? "Whatsapp" : "-"}</TableCell>
                      <TableCell>
                        <Button onClick={() => handleOpen(row.id)}>Delete</Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5} align="center">
                      No vendors found.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Grid>
            <PaginationComponent
              jumpPage={jumpPage}
              handleJumpPageChange={handleJumpPageChange}
              handleKeyPress={handleKeyPress}
              handleJumpToPage={handleJumpToPage}
              totalPages={totalPages}
              pageNum={pageNum}
              handleChangePage={handleChangePage}
              perPage={perPage}
            />
            </Grid>
            {/* <TablePagination
              component="div"
              count={totalPages * perPage}
              page={pageNum}
              onPageChange={handleChangePage}
              rowsPerPage={perPage}
              rowsPerPageOptions={[10]}
              onRowsPerPageChange={() => {}}
            /> */}
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default VendorList;
