import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

const initialState = {};

let headers = {
  "Content-Type": "application/json",
  Authorization: window.localStorage.accessToken,
};
// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const createContact = createAsyncThunk(
  "contacts/create-contact",
  async (contactData = {}) => {
    console.log(contactData);
    const queryParams = `name=${contactData.name}&email=${contactData.email}&phone=${contactData.phone}&whatsapp_number=${contactData.whatsapp_number}&store_id=${contactData.store_id}&contact_type=${contactData.contact_type}`;

    let url = `${wedlyEnv.baseUrl}/admin/store_contacts?${queryParams}`;

    return fetch(url, {
      method: "POST",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const addContactSlice = createSlice({
  name: "addcontact",
  initialState,
  reducers: {
    // setContactName: (state, action) => {
    //   console.log(action.payload);
    //   state.contactData.contactName = action.payload;
    // },
    // setEmail: (state, action) => {
    //   state.contactData.email = action.payload;
    // },
    // setPhone: (state, action) => {
    //   state.contactData.phone = action.payload;
    // },
    // setWhatsAppNumber: (state, action) => {
    //   state.contactData.whatsapp_number = action.payload;
    // },
    // setDefaults: (state, action) => {
    //   state.contactData = {
    //     contactName: "",
    //     phone: "",
    //     email: "",
    //     whatsapp_number: "",
    //   };
    // },
  },
  extraReducers: {
    [createContact.fulfilled]: (state, action) => {
      state.message = "Contact added sucsessfully";
      state.loading = false;
    },
    [createContact.pending]: (state, action) => {
      state.loading = true;
    },
    [createContact.rejected]: (state, action) => {
      state.message = "Failed to Add Contact";
      state.loading = false;
    },
  },
});

export default addContactSlice.reducer;
