import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

import qs from "query-string";

const initialState = {
  trackingRecords: [],
  loading: false,
  pageNum: 0,
  previousPage: null,
  nextPage: 1,
  totalPages: 0,
  perPage: 10,
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const getTrackingDetails = createAsyncThunk(
  "stores/getTrackingDetails",
  async (filterArr = {}, pageNum = null) => {
    console.log(filterArr);
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };

    let url = `${wedlyEnv.baseUrl}/admin/tracking`;
    // const queryParams = {
    //   store_id: "2",
    //   city_id: "2",
    // };
    // console.log(qs.stringify(queryParams));
    // if (pageNum) {
    //   url += `?page=${pageNum + 1}`;
    // }

    // if (Object.keys(filterArr).length > 0 && pageNum) {
    //   const add = qs.stringify(filterArr);
    //   console.log(add);
    //   url += `?${add}&page=${pageNum + 1}`;
    // } else if (Object.keys(filterArr).length > 0) {
    //   const add = qs.stringify(filterArr);
    //   console.log(add);
    //   url += `?${add}`;
    // } else if (pageNum) {
    //   url += `?page=${pageNum + 1}`;
    // }
    console.log(pageNum);
    if (Object.keys(filterArr).length > 0) {
      url += `?${qs.stringify(filterArr)}`;
    }

    console.log(url);

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error);
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const trackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    setPage: (state, action) => {
      console.log(action);
      state.pageNum = action.payload;
    },
  },

  extraReducers: {
    [getTrackingDetails.fulfilled]: (state, action) => {
      console.log("tracking");
      console.log(action.payload.data);
      state.trackingRecords = action.payload.data.tracking;
      //   state.previousPage = action.payload.data.prev_page
      //     ? action.payload.data.prev_page - 1
      //     : null;
      //   state.nextPage = action.payload.data.next_page
      //     ? action.payload.data.next_page - 1
      //     : null;
      //   state.perPage = action.payload.data.count;
      //   state.totalPages = action.payload.data.total_pages;
      //   state.pageNum = action.payload.data.current_page - 1;
      state.loading = false;
    },
    [getTrackingDetails.pending]: (state, action) => {
      state.loading = true;
    },
    [getTrackingDetails.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { setPage } = trackingSlice.actions;

export default trackingSlice.reducer;
