import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

const initialState = {
  storeDocuments: [],
  loading: false,
};

// We use Reducers in store to manipulate existing state in the page
// We use extra reducers to fetch data. We are using toolkit createAsyncThunk for that

export const getStoreDocuments = createAsyncThunk(
  "stores/get-storeDocs",
  async (store_id) => {
    let headers = {
      "Content-Type": "application/json",
      Authorization: window.localStorage.accessToken,
    };

    let url = `${wedlyEnv.baseUrl}/admin/store_documents?store_id=${store_id}`;

    // console.log(url);

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        // console.log(response);
        // console.log("Documents");
        return response.json();
      },
      (error) => {
        // console.log(error);
        console.log(error.message); //=> String
        return error;
      }
    );
  }
);

export const storeDocsSlice = createSlice({
  name: "storeDocs",
  initialState,
  reducers: {},

  extraReducers: {
    [getStoreDocuments.fulfilled]: (state, action) => {
      state.storeDocuments = action.payload.data.store_documents;
      state.loading = false;
    },
    [getStoreDocuments.pending]: (state, action) => {
      state.loading = true;
    },
    [getStoreDocuments.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export default storeDocsSlice.reducer;
