import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { wedlyEnv } from "../../config";

const initialState = {
  rows: [],
  page: 1,
  totalPages: 0,
  search: "",
  loading: false,
};

let headers = {
  Authorization: window.localStorage.accessToken,
};

export const getGroupsList = createAsyncThunk(
  "admin/groups-list",
  async (data = {}) => {
    console.log("Api Called");

    let { pageNum, search } = data;
    let url = `${wedlyEnv.baseUrl}/admin/store_groups`;

    if (pageNum) {
      url += `?page=${pageNum}`;
    }

    return fetch(url, {
      method: "GET",
      headers,
    }).then(
      (response) => {
        // console.log("tfyguhijok");
        // console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const makeOnline = createAsyncThunk(
  "admin/make-group-online",
  async (data = {}) => {
    let url = `${wedlyEnv.baseUrl}/admin/store_groups/${data.id}/make_online`;

    let formData = new FormData();
    formData.append("is_online", true);

    formData.append("website", data.website);

    // formData.append("is_default", true);
    // formData.append("for", data.coupon_code);
    // formData.append("store_id", data.store_id);

    return fetch(url, {
      body: formData,
      method: "POST",
      headers,
    }).then(
      (response) => {
        // console.log("tfyguhijok");
        // console.log(response);
        return response.json();
      },
      (error) => {
        console.log(error.message);
        return error;
      }
    );
  }
);

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setPage: (state, action) => {
      // console.log(action);
      state.page = action.payload;
    },
    setSearch: (state, action) => {
      // console.log(action);
      state.search = action.payload;
    },
  },

  extraReducers: {
    [getGroupsList.fulfilled]: (state, action) => {
      console.log(action.payload.data);
      state.rows = action.payload.data.store_groups;
      state.totalPages = action.payload.data.total_pages;
      state.page = action.payload.data.current_page;
      state.loading = false;
    },
    [getGroupsList.pending]: (state, action) => {
      state.loading = true;
    },
    [getGroupsList.rejected]: (state, action) => {
      state.loading = false;
    },
  },
});

export const { setPage, setSearch } = groupsSlice.actions;

export default groupsSlice.reducer;
